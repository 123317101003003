import { Component } from '@angular/core';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { RateCard, RateCardLine } from '../rate-card.class';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { ServicesService } from '../../services/services.service';
import { Observable, tap, switchMap, BehaviorSubject, of } from 'rxjs';
import { Service } from '../../services/service.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientRate, Client } from '../../clients/client.class';
import { RateCardsService } from '../rate-cards.service';

@Component({
  selector: 'app-rate-card-details',
  templateUrl: './rate-card-details.component.html',
  styleUrl: './rate-card-details.component.scss'
})
export class RateCardDetailsComponent extends JangoComponentBase {
  id: string = '';
  form: FormGroup;
  data$: Observable<RateCard>;
  services$: Observable<Service[]>;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNew = true;

  constructor (private Auth: AuthService, private Router: Router, private ActiveRoute: ActivatedRoute, private RateCards: RateCardsService, private Services: ServicesService, private fb: ClassValidatorFormBuilderService, private ngFB: FormBuilder) {
    super(Auth);

    this.form = this.fb.group(RateCard, {
      _id: [''],
      name: [''],
      services: this.fb.array([]),
      service: [''],
    });

    this.services$ = this.Services.findAll();

    this.data$ = this.ActiveRoute.params.pipe(
      tap((params) => this.id = params['id']),
      switchMap((params) => this.getData(params['id'])),
      tap((data) => this.populateForm(data)),
      tap((data) => this.isNew = !data._id || data._id === 'create' ),
    );
  }

  addService(rate?: RateCardLine) {
    this.services.push(this.fb.group(RateCardLine, {
      service: [rate?.service || ''],
      serviceId: [rate?.serviceId || ''],
      unit: [rate?.unit || ''],
      pricingModel: [rate?.pricingModel || 'full'],
      perHour: [rate?.perHour || 0],
      travelHour: [rate?.travelHour || 0],
      mileage: [rate?.mileage || 0],
      perUnit: [rate?.perUnit || 0]
    }));
  }

  get services() {
    return this.form.get('services') as FormArray;
  }

  setService(index: number) {
    debugger;
  }

  serviceSelected() {
    const svc = this.form.get('service')?.value;
    if (!svc) return;

    this.addService({
      serviceId: svc._id,
      service: svc.name,
      unit: svc.unit,
      pricingModel: svc.pricingModel,
      perHour: 0,
      travelHour: 0,
      mileage: 0,
      perUnit: 0,
    });
  }

  getData(id: string =  ''): Observable<RateCard> {
    const rateCard: RateCard = {
      name: '',
      services: [],
      _createdBy: '',
      _createdOn: null,
      _updatedBy: '',
      _updatedOn: null
    };
    if (!id) return of(rateCard);
    return this.RateCards.findById(id);
  }

  populateForm(data: RateCard) {
    this.form.patchValue(data);
    (data.services || []).forEach( r => this.addService(r));
  }

  save() {
    this.saving$.next(true);
    this.data$ = this.RateCards.save({ ...this.form.value, services: this.services.value }).pipe(
      tap(() => this.saving$.next(false) ),
      tap((data) => this.Router.navigate(['rate-cards'])),
    );
  }

  cancel() {
    this.Router.navigate(['rate-cards']);
  }
}
