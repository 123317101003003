import { Component } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, of, switchMap, tap } from 'rxjs';
import { BankDetails, Interpreter, InterpreterFile, InterpreterMembership, InterpreterQualification, InterpreterRate, InterpreterRates } from '../interpreters.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { AuthService } from '../../auth/auth.service';
import { Client } from '../../clients/client.class';
import { InterpretersService } from '../interpreters.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { IGenericLookup } from '../../shared/generic-lookup.interface';
import { EnumToArray } from '../../shared/enum-helper';
import { Title } from '../../shared/title.enum';
import { Address } from '../../shared/address.class';
import { Languages } from '../../shared/languages';
import { SharedService } from '../../shared/shared.service';
import { SkillsService } from '../../skills/skills.service';
import { Skill } from '../../skills/skill.class';
import { Service } from '../../services/service.class';
import { ServicesService } from '../../services/services.service';

@Component({
  selector: 'app-interpreter-details',
  templateUrl: './interpreter-details.component.html',
  styleUrls: ['./interpreter-details.component.scss']
})
export class InterpreterDetailsComponent extends JangoComponentBase {
  id: string = '';
  data$: Observable<Interpreter>;
  form: FormGroup;
  newQualificationForm: FormGroup;
  newLanguageForm: FormGroup;
  newMembershipForm: FormGroup;
  fileForm: FormGroup;
  saving: boolean = false;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  titles$: Observable<IGenericLookup[]>;
  isNew = true;
  languages$: Observable<Skill[]>;
  currencies$: Observable<IGenericLookup[]>;
  qualifications$: Observable<IGenericLookup[]>;
  memberships$: Observable<IGenericLookup[]>;
  services$: Observable<Service[]>;
  interpreterFiles$!: Observable<InterpreterFile[]>;
  interpreterLanguages: string[] = [];
  interpreterQualifications: InterpreterQualification[] = [];
  interpreterMemberships: string[] = [];
  fileData: any;
  selectedMembership: IGenericLookup | null = null;
  // selectedLanguage: FormControl

  constructor(private  Auth: AuthService, private Interpreters: InterpretersService, private Shared: SharedService, private Services: ServicesService, private Skills: SkillsService, private Router: Router, private ActiveRoute: ActivatedRoute, private fb: ClassValidatorFormBuilderService, private ngFB: FormBuilder) {
    super(Auth);
    
    this.titles$ = of(EnumToArray(Title));
    this.qualifications$ = Interpreters.getQualifications();
    this.memberships$ = Interpreters.getMemberships();
    this.languages$ = Skills.findAll();
    this.currencies$ = Shared.getCurrencies();
    this.services$ = this.Services.findAll();

    this.newQualificationForm = this.ngFB.group({
      type: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });

    this.newLanguageForm = this.ngFB.group({
      selectedLanguage: ['', [Validators.required]],
    });

    this.newMembershipForm = this.ngFB.group({
      selectedMembership: ['', [Validators.required]],
    });

    this.fileForm = this.ngFB.group({
      fileDescription: ['', [Validators.required]],
      selectedFile: ['', [Validators.required]]
    })

    this.form = this.fb.group(Interpreter, {
      _id: [''],
      title: [''],
      firstName: [''],
      lastName: [''],
      linguistNumber: [''],
      email: [''],
      telephone: [''],
      mobile: [''],
      utr: [''],
      currency: [''],
      notes: [''],
      status: [true],
      address: this.fb.group(Address, {
        line1: [''],
        line2: [''],
        city: [''],
        county: [''],
        postcode: [''],
        country: [''],
      }),
      willQuote: [false],
      rates: this.fb.array([]),
      bankDetails: this.fb.group(BankDetails, {
        name: [''],
        accNo: [''],
        sort: [''],
      }),
      rating: [0],
      service: [''],
      // languages: this.fb.array([])
    });

    this.data$ = this.ActiveRoute.params.pipe(
      tap((params) => this.id = params['id']),
      switchMap((params) => this.getData(params['id'])),
      tap((data) => this.populateForm(data)),
      tap((data) => this.isNew = !data._id || data._id === 'create' ),
      tap((data) => this.interpreterQualifications = data.qualifications),
      tap((data) => this.interpreterLanguages = data.languages),
      tap((data) => this.interpreterMemberships = data.memberships),
      tap((data) => this.interpreterFiles$ = this.Interpreters.getFilesForInterpreter(data._id!))
    );
  }

  addRate(rate?: InterpreterRate) {
    this.rates.push(this.fb.group(InterpreterRate, {
      serviceId: [rate?.serviceId || ''],
      service: [rate?.service || ''],
      pricingModel: [rate?.pricingModel || ''],
      unit: [rate?.unit || ''],
      oneHourRate: [rate?.oneHourRate || 0],
      standardHourRate: [rate?.standardHourRate || 0],
      travelHourRate: [rate?.travelHourRate || 0],
      mileageRate: [rate?.mileageRate || 0],
      unitRate: [rate?.unitRate || 0],
    }));
  }

  get rates() {
    return this.form.get('rates') as FormArray;
  }

  serviceSelected() {
    const svc = this.form.get('service')?.value;
    if (!svc) return;

    this.addRate({
      serviceId: svc._id,
      service: svc.name,
      unit: svc.unit,
      pricingModel: svc.pricingModel,
      oneHourRate: 0,
      standardHourRate: 0,
      travelHourRate: 0,
      mileageRate: 0,
      unitRate: 0,
    });
  }

  deleteLanguage(index: number) {
    this.interpreterLanguages.splice(index, 1);
  }

  getData(id: string =  ''): Observable<Interpreter> {
    let interpreter: Interpreter = {
      title: Title.Mr,
      firstName: '',
      lastName: '',
      linguistNumber: '',
      email: '',
      telephone: '',
      mobile: '',
      address: new Address,
      willQuote: false,
      languages: [],
      qualifications: [],
      memberships: [],
      utr: '',
      currency: '',
      notes: '',
      rates: [],
      bankDetails: new BankDetails,
      rating: 0,
      _createdBy: '',
      _createdOn: null,
      _updatedBy: '',
      _updatedOn: null
    };
    if (!id) return of(interpreter);
    return this.Interpreters.findById(id);
  }

  populateForm(data: Interpreter) {
    this.form.patchValue(data);
    (data.rates || []).forEach( r => this.addRate(r))
  }

  addLanguage() {
    console.log(this.form.value.selectedLanguage)
    this.interpreterLanguages = this.interpreterLanguages || [];
    this.interpreterLanguages.push(this.newLanguageForm.value.selectedLanguage);
  }

  removeLanguage(index: number) {
    this.interpreterLanguages.splice(index, 1);
  }

  addMembership() {
    this.interpreterMemberships = this.interpreterMemberships || [];
    this.interpreterMemberships.push(this.newMembershipForm.value.selectedMembership);
  }

  removeMembership(index: number) {
    this.interpreterMemberships.splice(index, 1);
  }

  addQualification() {
    console.log(this.form.value.selectedLanguage)
    this.interpreterQualifications = this.interpreterQualifications || [];
    this.interpreterQualifications.push({
      type: this.newQualificationForm.value.type,
      description: this.newQualificationForm.value.description
    });
  }

  removeQualification(index: number) {
    this.interpreterQualifications.splice(index, 1);
  }

  onFileSelected(event: any): void {
    this.fileData = event.target.files[0];
    // this.uploadFile(fileToUpload.items(0));
  }

  async uploadFile() {
    function _stripFilename(fn: string) {
      return fn.substring(fn.lastIndexOf("\\") + 1, fn.length); 
    }

    await this.Interpreters.uploadFileForInterpreter(this.id, _stripFilename(this.fileForm.value.selectedFile), this.fileForm.value.fileDescription, this.fileData);
    this.interpreterFiles$ = this.Interpreters.getFilesForInterpreter(this.id);
  }

  async downloadFile(fn: string) {
    const url: {url: string} = await firstValueFrom(this.Shared.getPreSignedURLForFile(fn, 'GET'));
    console.log(url.url);
    window.open(url.url);
  }

  async deleteFileForInterpreter(id: string) {
    await firstValueFrom(this.Interpreters.deleteFileForInterpreter(this.id, id));
    this.interpreterFiles$ = this.Interpreters.getFilesForInterpreter(this.id);
  }

  save() {
    let data = { ...this.form.value, languages: this.interpreterLanguages || [], qualifications: this.interpreterQualifications || [], memberships: this.interpreterMemberships || [] };
    delete data.selectedLanguage;
    delete data.selectedQualification;

    this.saving$.next(true);
    this.data$ = this.Interpreters.save(data).pipe(
      tap(() => this.saving$.next(false) ),
      // tap((data) => this.Router.navigate(['interpreters'])),
    );
  }

  cancel() {
    this.Router.navigate(['interpreters']);
  }
}
