import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../guards/auth-guard.service';
import { InterpretersListComponent } from './interpreters-list/interpreters-list.component';
import { InterpreterDetailsComponent } from './interpreter-details/interpreter-details.component';

const routes: Routes = [
  {
    path: 'interpreters',
    data: { permission: ['interpreters'] },
    component: InterpretersListComponent
  },
  {
    path: 'interpreters/create',
    data: { permission: ['interpreters'] },
    component: InterpreterDetailsComponent
  },
  {
    path: 'interpreters/:id',
    data: { permission: ['interpreters'] },
    component: InterpreterDetailsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InterpretersRoutingModule { }
