import { Injectable } from '@angular/core';
import { JangoBaseService } from '../shared/jango-base.service';
import { Client } from './client.class';
import { ApiService } from '../shared/api/api.service';
import { EnumToArray } from '../shared/enum-helper';
import { ClientSpecialism } from './client-specialism.enum';
import { Observable, of } from 'rxjs';
import { ClientStatus } from './client-status.enum';
import { ClientContact } from './client-contact.class';
import { IGenericLookup } from '../shared/generic-lookup.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends JangoBaseService<Client> {
  override endpoint = 'clients';
  public specialisms$: Observable<IGenericLookup[]>;
  public statuses$: Observable<IGenericLookup[]>;

  constructor(override API: ApiService) {
    super(API);

    this.specialisms$ = of(EnumToArray(ClientSpecialism));
    this.statuses$ = of(EnumToArray(ClientStatus));
  }

  getClientTypes() {
    return this.API.get<IGenericLookup[]>('clients/types');
  }

  getContacts(id: string) {
    return this.API.get<ClientContact[]>(`clients/${id}/contacts`);
  }

  createContact(id: string, data: ClientContact) {
    return this.API.post<ClientContact>(`clients/${id}/contacts`, data);
  }

  updateContact(clientId: string, data: ClientContact) {
    return this.API.patch<ClientContact>(`clients/${clientId}/contacts/${data._id}`, data);
  }

  saveContact(clientId: string, payload: ClientContact) {
    if (payload._id) {
      return this.updateContact(payload._id, payload);
    } else {
      // Just to be sure
      delete payload._id;
      return this.createContact(clientId, payload);
    }
  }
}
