<div class="container-xl" *ngIf="data$ | async as data">
  <h3>Interpreter Details</h3>

  <form [formGroup]="form">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">  
      <li [ngbNavItem]="1">
        <button ngbNavLink>Details</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-3">
                <label class="form-label">Title</label>
                <select class="form-select" formControlName="title">
                  <option *ngFor="let title of titles$ | async" [value]="title.value">{{ title.key }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">First Name</label>
                <input class="form-control" formControlName="firstName" placeholder="First Name">
              </div>
              <div class="mb-3">
                <label class="form-label">Last Name</label>
                <input class="form-control" formControlName="lastName" placeholder="Last Name">
              </div>
              <div class="mb-3">
                <label class="form-label">Linguist Number</label>
                <input class="form-control" formControlName="linguistNumber" placeholder="Linguist Number">
              </div>
              <div class="mb-3">
                <label class="form-label">Email</label>
                <input class="form-control" formControlName="email" type="email" placeholder="Email">
              </div>
              <div class="mb-3">
                <label class="form-label">Telephone</label>
                <input class="form-control" formControlName="telephone" placeholder="Telephone">
              </div>
              <div class="mb-3">
                <label class="form-label">Mobile</label>
                <input class="form-control" formControlName="mobile" placeholder="Mobile">
              </div>
              <div class="mb-3">
                <label class="form-label">Rating</label>
                <star-rating-control formControlName="rating" size="large" [hoverEnabled]="true"></star-rating-control>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <button ngbNavLink>Address</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <div formGroupName="address">
                <div class="mb-3">
                  <label class="form-label" >Line 1</label>
                  <input class="form-control" formControlName="line1" placeholder="Line 1">
                </div>
                
                <div class="mb-3">
                  <label class="form-label" >Line 2</label>
                  <input class="form-control" formControlName="line2" placeholder="Line 2">
                </div>
        
                <div class="mb-3">
                  <label class="form-label" >City</label>
                  <input class="form-control" formControlName="city" placeholder="City">
                </div>
        
                <div class="mb-3">
                  <label class="form-label" >County</label>
                  <input class="form-control" formControlName="county" placeholder="County">
                </div>
                
                <div class="mb-3">
                  <label class="form-label" >Postcode</label>
                  <input class="form-control" formControlName="postcode" placeholder="Postcode">
                </div>
                
                <div class="mb-3">
                  <label class="form-label" >Country</label>
                  <input class="form-control" formControlName="country" placeholder="Country">
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <button ngbNavLink>Payment</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-xl-6">
              <div class="mb-3">
                <label class="form-label">UTR</label>
                <input class="form-control" formControlName="utr" placeholder="UTR">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="mb-5">
                <label class="form-label" >Currency</label>
                <select class="form-control" formControlName="currency">
                  <option *ngFor="let c of currencies$ | async" [value]="c.key">{{ c.key }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-xl-6" formGroupName="bankDetails">
              <h6>Payment Details</h6>
              <div class="mb-3">
                <label class="form-label">Bank Name</label>
                <input class="form-control" formControlName="name" placeholder="Bank Name">
              </div>
              <div class="mb-3">
                <label class="form-label">Account Number</label>
                <input class="form-control" formControlName="accNo" placeholder="Account Number">
              </div>
              <div class="mb-3">
                <label class="form-label">Sort Code</label>
                <input class="form-control" formControlName="sort" placeholder="Sort Code">
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="4">
        <button ngbNavLink>Rates</button>
        <ng-template ngbNavContent>

          <div class="alert alert-warning">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="willQuote" formControlName="willQuote">
              <label class="form-check-label" for="willQuote">
                This interpreter will quote for work.
              </label>
            </div>
          </div>

          <table class="table mb-5" *ngIf="!form.value.willQuote">
            <thead>
              <tr>
                <th>Service</th>
                <th>One Hour/per Unit</th>
                <th>Standard Hour</th>
                <th>Travel Hour</th>
                <th>Mileage</th>
              </tr>
            </thead>
            <tbody formArrayName="rates">
              <tr *ngFor="let rate of rates.controls; let i = index" [formGroupName]="i">
                <th class="align-middle">
                  {{ rate.value.service }}
                </th>
                <td>
                  <input type="number" class="form-control" formControlName="oneHourRate" type="number" *ngIf="rate.value.pricingModel === 'full'">
                  <div class="input-group" *ngIf="rate.value.pricingModel === 'unit'">
                    <input type="number" class="form-control" formControlName="unitRate" type="number">
                    <span class="input-group-text">per {{ rate.value.unit }}</span>
                  </div>
                </td>
                <td>
                  <input type="number" class="form-control" formControlName="standardHourRate" type="number" *ngIf="rate.value.pricingModel === 'full'">
                </td>
                <td>
                  <input type="number" class="form-control" formControlName="travelHourRate" type="number" *ngIf="rate.value.pricingModel === 'full'">
                </td>
                <td>
                  <input type="number" class="form-control" formControlName="mileageRate" type="number" *ngIf="rate.value.pricingModel === 'full'">
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5">
                  <!--
                  <button class="btn btn-primary" (click)="addRate()">
                    <fa-icon [icon]="Icons.newRecord"></fa-icon>
                    Add Rate
                  </button>
                -->
                <select class="form-select" formControlName="service" (change)="serviceSelected()">
                  <option value="">(Add service)</option>
                  <option *ngFor="let s of services$ | async" [ngValue]="s">{{s.name}}</option>
                </select>
                </td>
              </tr>
            </tfoot>
          </table>
        </ng-template>
      </li>


      <li [ngbNavItem]="5">
        <button ngbNavLink>Languages</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">

              <form [formGroup]="newLanguageForm">
                <table class="table table-striped">
                  <tbody>
                    <tr *ngFor="let l of interpreterLanguages || []; index as i">
                      <td>
                        {{ l }}
                      </td>
                      <td>
                        <fa-icon class="float-end pointer" [icon]="Icons.delete" (click)="removeLanguage(i)"></fa-icon>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2" style="padding: 0">
                        <div class="input-group mt-3 mb-3">
                          <select class="form-control" formControlName="selectedLanguage">
                            <option value="">(Select language)</option>
                            <option *ngFor="let l of languages$ | async" value="{{ l.name }}">{{ l.name }}</option>
                          </select>
                          <button class="btn btn-primary" type="button" [disabled]="!this.newLanguageForm.valid" (click)="addLanguage()">
                            <fa-icon [icon]="Icons.newRecord"></fa-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </form>

            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <button ngbNavLink>Qualifications</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <form [formGroup]="newQualificationForm">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Description</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let l of interpreterQualifications || []; index as i">
                      <td>{{l.type}}</td>
                      <td>{{l.description}}</td>
                      <td>
                        <fa-icon class="float-end pointer" [icon]="Icons.delete" (click)="removeQualification(i)"></fa-icon>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style="padding-left: 0">
                        <select class="form-control" formControlName="type">
                          <option value="">(Select qualification)</option>
                          <option *ngFor="let l of qualifications$ | async" [value]="l.value">{{ l.value }}</option>
                        </select>
                      </td>
                      <td colspan="2" style="padding-right: 0">
                        <div class="input-group">
                          <input type="text" class="form-control" required formControlName="description">
                          <button class="btn btn-primary" type="button" [disabled]="!this.newQualificationForm.valid" (click)="addQualification()">
                            <fa-icon [icon]="Icons.newRecord"></fa-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </form>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <button ngbNavLink>Memberships</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <form [formGroup]="newMembershipForm">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Membership</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let l of interpreterMemberships || []; index as i">
                      <td>{{l}}</td>
                      <td>
                        <fa-icon class="float-end pointer" [icon]="Icons.delete" (click)="removeMembership(i)"></fa-icon>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2" style="padding: 0">
                        <div class="input-group mt-3 mb-3">
                          <select class="form-control" formControlName="selectedMembership">
                            <option value="">(Select membership)</option>
                            <option *ngFor="let l of memberships$ | async" [value]="l.value">{{ l.value }}</option>
                          </select>
                          <button class="btn btn-primary" type="button" [disabled]="!this.newMembershipForm.valid" (click)="addMembership()">
                            <fa-icon [icon]="Icons.newRecord"></fa-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </form>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="8">
        <button ngbNavLink>Files</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6" [formGroup]="fileForm">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Filename</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let l of interpreterFiles$ | async; index as i">
                    <td>
                      <a href="javascript: void(0)" (click)="downloadFile(l.filename)">
                        {{ l.description }}
                      </a>
                    </td>
                    <td>
                      <fa-icon class="float-end pointer" [icon]="Icons.delete" (click)="deleteFileForInterpreter(l._id!)"></fa-icon>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td style="padding-left: 0;">
                      <input type="text" class="form-control" formControlName="fileDescription" placeholder="Description">
                    </td>
                    <td style="padding-right: 0">
                      <div class="input-group">
                        <input type="file" class="form-control" formControlName="selectedFile" (change)="onFileSelected($event)">
                        <button class="btn btn-primary" [disabled]="!fileForm.valid" (click)="uploadFile()">
                          <fa-icon [icon]="Icons.newRecord"></fa-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="9">
        <button ngbNavLink>Notes</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <textarea class="form-control" formControlName="notes" placeholder="Notes" style="height: 40ex"></textarea>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>
    <hr>
    <div class="mb-3 float-end">
      <a class="btn btn-outline-primary me-2" routerLink="/interpreters">Cancel</a>
      <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
        <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
        <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
        Save
      </button>
    </div>
  </form>

</div>
