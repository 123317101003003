import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavModule } from './nav/nav.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptorInterceptor } from './interceptors/error-interceptor.interceptor';
import { GenericModalComponent } from './shared/modal/generic-modal/generic-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JwtInterceptorInterceptor } from './interceptors/jwt-interceptor.interceptor';
import { UsersModule } from './users/users.module';
import { ClientsModule } from './clients/clients.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClassValidatorFormBuilderModule } from 'ngx-reactive-form-class-validator';
import { InterpretersModule } from './interpreters/interpreters.module';
import { JobsModule } from './jobs/jobs.module';
import { StarRatingModule } from 'angular-star-rating';
import { SkillsModule } from './skills/skills.module';
import { RateCardsModule } from './rate-cards/rate-cards.module';
import { ServicesModule } from './services/services.module';

@NgModule({
  declarations: [
    AppComponent,
    GenericModalComponent,
    DashboardComponent,
  ],
  imports: [
    ClassValidatorFormBuilderModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NavModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    UsersModule,
    ClientsModule,
    InterpretersModule,
    JobsModule,
    HttpClientModule,
    FontAwesomeModule,
    StarRatingModule.forRoot(),
    SkillsModule,
    RateCardsModule,
    ServicesModule,
    // NgxCurrencyModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
