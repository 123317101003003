import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../guards/auth-guard.service';
import { RateCardsListComponent } from './rate-cards-list/rate-cards-list.component';
import { RateCardDetailsComponent } from './rate-card-details/rate-card-details.component';

const routes: Routes = [
  {
    path: 'rate-cards',
    component: RateCardsListComponent
  },
  {
    path: 'rate-cards/create',
    component: RateCardDetailsComponent
  },
  {
    path: 'rate-cards/:id',
    component: RateCardDetailsComponent
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RateCardsRoutingModule { }
