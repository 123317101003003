import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../users/users.service';

interface IReg {
  email: string;
  firstName: string;
  lastName: string;
}

interface IRegistrationFormGroup extends FormGroup {
  value: IReg;

  // We need to add these manually again, same fields as IUser
  controls: {
    email: AbstractControl;
    firstName: AbstractControl;
    lastName: AbstractControl;
  };
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  registerFailed = false;
  registerForm: IRegistrationFormGroup;

  constructor(private userService: UsersService, private formBuilder: FormBuilder) {
    this.registerForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
    }) as IRegistrationFormGroup;
  }

  async register() {
    try {
      this.registerFailed = false;
      const regData: IReg = { email: this.registerForm.value.email, firstName: this.registerForm.value.firstName, lastName: this.registerForm.value.lastName };
      await this.userService.register(regData);
    } catch (e) {
      this.registerFailed = true;
    }
  }
}
