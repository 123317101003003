import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobsRoutingModule } from './jobs-routing.module';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobDetailsComponent } from './job-details/job-details.component';
import { StarRatingModule } from 'angular-star-rating';
import { JobOffersListComponent } from './job-offers-list/job-offers-list.component';
import { JobOfferDetailsComponent } from './job-offer-details/job-offer-details.component';
import { DotdotdotPipe } from '../shared/dotdotdot.pipe';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [JobsListComponent, JobDetailsComponent, JobOffersListComponent, JobOfferDetailsComponent],
  imports: [
    CommonModule,
    JobsRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    StarRatingModule.forRoot(),
    // SharedModule,
  ],
  exports: [JobOffersListComponent],
  providers: [NgbActiveModal]
})
export class JobsModule { }
