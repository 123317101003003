import { IsDateString, IsNumber, IsOptional, IsString, Min, MinLength, ValidateNested } from 'class-validator';
import { JangoMongooseBase } from '../shared/jango-mongoose-base';
import { Address } from '../shared/address.class';

export class Job extends JangoMongooseBase {
  // _id: string = '';

  @IsString()
  jobNo: string = '';

  @IsString()
  parentReference: string = '';

  // @Prop({ required: true, type: JobClient })
  // client: JobClient = '';

  // @Prop({ required: true, type: JobInterpreter })
  // interpreter: JobInterpreter = '';

  @IsString()
  clientId: string = '';

  @IsString()
  clientName: string = '';

  @IsString()
  clientContactId: string = '';

  @IsString()
  interpreterId: string = '';

  @IsString()
  interpreterName: string = '';

  @IsNumber()
  durationRequested: number = 0;

  @IsNumber()
  durationBooked: number = 0;

  @IsString()
  date: string = '';

  @IsString()
  service: string = '';

  @IsString()
  serviceId: string = '';

  @IsString()
  pricingModel: string = '';

  @IsString()
  unit: string = '';

  @IsNumber()
  units: number = 0;

  @IsString()
  customerReference: string = '';

  @IsString()
  startTime: string = '';

  @IsString()
  endTime: string = '';

  @IsString()
  language: string = '';

  @IsString()
  @MinLength(3)
  endClient: string = '';

  @IsString()
  notes: string = '';

  @IsNumber()
  @Min(1)
  price: number = 0;

  @IsNumber()
  @IsOptional()
  fee?: number = 0;

  @IsNumber()
  @IsOptional()
  adjustment?: number = 0;

  @IsString()
  attendingProfessional: string = '';

  @ValidateNested()
  address: Address = {
    line1: '',
    line2: '',
    city: '',
    county: '',
    postcode: '',
    country: ''
  };

  @IsString()
  status: string = '';

  @IsString()
  rateCardId: string = '';
}