<div class="jobs">
  <form [formGroup]="form" class="mb-3">
    <div class="row">
      <div class="col-lg mb-3">
        <label for="jobNo" class="form-label">Job No</label>
        <input type="text" class="form-control form-control-sm" id="jobNo" placeholder="" formControlName="jobNo" (ngModelChange)="subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="clientId" class="form-label">Client</label>
        <select class="form-select form-select-sm" formControlName="clientId" id="clientId" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let c of clients$ | async" value="{{ c._id }}">{{ c.name }}</option>
        </select>
      </div>
      <div class="col-lg mb-3">
        <label for="interpreterId" class="form-label">Interpreter</label>
        <select class="form-select form-select-sm" formControlName="interpreterId" id="interpreterId" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let c of interpreters$ | async" value="{{ c._id }}">{{ c.firstName + ' ' + c.lastName }}</option>
        </select>
      </div>
      <div class="col-lg mb-3">
        <label for="endClient" class="form-label">Non-English Speaker</label>
        <input type="text" class="form-control form-control-sm" id="endClient" placeholder="" formControlName="endClient" (ngModelChange)="subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="dateFrom" class="form-label">Date from</label>
        <div class="input-group input-group-sm">
          <input
            class="form-control form-control-sm"
            id="dateFrom"
            name="dateFrom"
            formControlName="dateFrom"
            ngbDatepicker
            #dF="ngbDatepicker"
            (dateSelect)="search()"
            (blur)="search()"
          />
          <button class="btn btn-sm btn-primary" (click)="dF.toggle()" type="button">
            <fa-icon [icon]="Icons.calendar"></fa-icon>
          </button>
        </div>
      </div>
      <div class="col-lg mb-3">
        <label for="dateTo" class="form-label">Date to</label>
        <div class="input-group input-group-sm">
          <input
            class="form-control form-control-sm"
            id="dateTo"
            name="dateTo"
            formControlName="dateTo"
            ngbDatepicker
            #dT="ngbDatepicker"
            (dateSelect)="search()"
            (blur)="search()"
          />
          <button class="btn btn-sm btn-primary" (click)="dT.toggle()" type="button">
            <fa-icon [icon]="Icons.calendar"></fa-icon>
          </button>
        </div>
      </div>
      <div class="col-lg mb-3">
        <label for="service" class="form-label">Service</label>
        <select class="form-select form-select-sm" formControlName="service" id="service" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let s of services$ | async" value="{{ s.name }}">{{ s.name }}</option>
        </select>
      </div>
      <div class="col-lg mb-3">
        <label for="language" class="form-label">Language</label>
        <select class="form-select form-select-sm" formControlName="language" id="language" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let l of languages$ | async" value="{{ l.name }}">{{ l.name }}</option>
        </select>
      </div>
      <div class="col-lg mb-3">
        <label for="status" class="form-label">Status</label>
        <select class="form-select form-select-sm" formControlName="status" id="status" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let s of statuses$ | async" value="{{ s.value }}">{{ s.value }}</option>
        </select>
      </div>
    </div>
  </form>
  <div>
    <table class="table table-hover table-striped">
      <thead>
        <th>Job No</th>
        <th>Client</th>
        <th class="d-none d-md-table-cell">Non-English Speaker</th>
        <th class="d-none d-md-table-cell">Language</th>
        <th class="d-none d-md-table-cell">Service</th>
        <th class="d-none d-md-table-cell">Date</th>
        <th class="d-none d-md-table-cell">Interpreter</th>
        <th class="d-none d-md-table-cell">Status</th>
      </thead>
      <tbody *ngIf="data$ | async as data">
        <tr *ngFor="let job of data.data">
          <td scope="row" class="px-0">
            <a routerLink="/jobs/{{job._id}}">
              {{ job.jobNo }}
            </a>
          </td>
          <td class="px-0">
            {{ job.clientName }}
          </td>
          <td class="d-none d-md-table-cell px-0">
            {{ job.endClient }}
          </td>
          <td class="d-none d-md-table-cell px-0">
            {{ job.language }}
          </td>
          <td class="d-none d-md-table-cell px-0">
            {{ job.service }}
          </td>
          <td class="d-none d-md-table-cell px-0">
            {{ job.date | date:'dd/MM/YYYY' }}
          </td>
          <td class="d-none d-md-table-cell px-0">
            {{ job.interpreterName }}
          </td>
          <td class="d-none d-md-table-cell px-0">
            <button class="btn btn-sm" [ngClass]="getStatusClass(job.status)" style="width: 10em">
              {{ job.status }}&nbsp;
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col">
        <a class="btn btn btn-primary" routerLink="create">
          <fa-icon [icon]="Icons.newRecord"></fa-icon>
          Add
        </a>
      </div>
      <div class="col">
        <ngb-pagination *ngIf="data$ | async as data"
          class="float-end"
          [page]="data.pageNo"
          [pageSize]="data.pageSize"
          (pageChange)="pageChanged($event)"
          [collectionSize]="data.totalCount"></ngb-pagination>
      </div>
    </div>
  </div>

</div>