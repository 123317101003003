import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface GenericModalConfiguration {
  title: string;
  body: string;
  closeText?: string;
  type?: 'danger' | 'warning' | 'success'; // Configures the alert styling. Omit for plain text.
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {
  config: GenericModalConfiguration | undefined;

  constructor (
    public activeModal: NgbActiveModal
  ) {}
}
