import { faCalendar, faClock, faEnvelope, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition, faCalculator, faCheck, faMultiply, faPlus, faPrint, faSave, faSearch, faSpinner, faStar } from "@fortawesome/free-solid-svg-icons";

const Icons = {
  accepted: faCheck,
  calculate: faCalculator,
  calendar: faCalendar,
  declined: faMultiply,
  delete: faTrashCan,
  email: faEnvelope,
  loading: faSpinner,
  newRecord: faPlus,
  print: faPrint,
  save: faSave,
  search: faSearch,
  star: faStar,
  tentative: faClock,
};

export { Icons }