import { IsOptional, IsString } from 'class-validator';
import { JangoMongooseBase } from '../shared/jango-mongoose-base';

export class Skill extends JangoMongooseBase {
  @IsString()
  name: string = '';

  @IsOptional()
  @IsString()
  preferredInterpreterId: string = '';

  @IsOptional()
  @IsString()
  preferredInterpreterName: string = '';
}
