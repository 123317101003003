import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ApiService } from '../shared/api/api.service';
import { IGenericLookup } from '../shared/generic-lookup.interface';
import { JangoBaseService } from '../shared/jango-base.service';
import { Job } from './job.class';
import { JobOfferView } from './job-offer-view.interface';
import { JobOfferSearchResult } from './job-offer-search-result.interface';
import { IJobPriceRequest } from './job-price-request.interface';
import { IJobPrice } from './job-price.interface';
import { JobFile } from './job-file.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobsService extends JangoBaseService<Job> {
  override endpoint = 'jobs';
  // public specialisms$: Observable<IGenericLookup[]>;
  // public statuses$: Observable<IGenericLookup[]>;

  constructor(override API: ApiService, private HTTP: HttpClient) {
    super(API);

    // this.specialisms$ = of(EnumToArray(ClientSpecialism));
    // this.statuses$ = of(EnumToArray(ClientStatus));
  }

  getStatuses() {
    return this.API.get<IGenericLookup[]>('jobs/statuses');
  }

  getOffers(id: string) {
    return this.API.get<any[]>(`jobs/${id}/offers`);
  }

  getMyOffers() {
    return this.API.get<JobOfferSearchResult[]>(`jobs/offers`);
  }

  getMyAssignments() {
    return this.API.get<JobOfferSearchResult[]>(`jobs/assignments`);
  }

  getJobOffer(id: string) {
    return this.API.get<JobOfferView>(`jobs/offers/${id}`);
  }

  sendOffer(jobId: string, interpreterId: string, fee: number = 0) {
    return this.API.post<any>(`jobs/${jobId}/offer`, { jobId, interpreterId, fee });
  }

  getClientPrice(dto: IJobPriceRequest) {
    return this.API.post<IJobPrice>('jobs/price', dto);
  }

  acceptOffer(id: string, notes: string, fee?: number) {
    return this.API.post<any>(`jobs/offers/${id}/accept`, { notes, fee });
  }

  declineOffer(id: string, notes: string, fee?: number) {
    return this.API.post<any>(`jobs/offers/${id}/decline`, { notes, fee });
  }

  tentativeOffer(id: string, notes: string, fee?: number) {
    return this.API.post<any>(`jobs/offers/${id}/tentative`, { notes, fee });
  }

  completeJob(id: string, data: { durationCompleted: number, mileage: number, expenses: number }) {
    return this.API.post<any>(`jobs/${id}/complete`, data);
  }

  assignInterpreter(jobId: string, interpreterId: string) {
    return this.API.post<any>(`jobs/${jobId}/assign`, { interpreterId });
  }

  unassignInterpreter(jobId: string, interpreterId: string) {
    return this.API.post<any>(`jobs/${jobId}/unassign`, { interpreterId });
  }

  public getStatusClass(status: string) {
    let cls = '';
    switch (status) {
      case 'Offered':
        cls = 'btn-warning';
        break;
      case 'Responded':
        cls = 'btn-warning';
        break;
      case 'Assigned':
        cls = 'btn-success';
        break;
      case 'Complete':
        cls = 'btn-success';
        break;
      case 'Payment Approved':
        cls = 'btn-success';
        break;
      default:
        cls = 'btn-outline-primary';
    }
    return cls;
  }

  public getOfferClass(status: string) {
    let txt = '';
    switch (status) {
      case 'available':
        txt = 'btn-success';
        break;
      case 'tentative':
        txt = 'btn-warning';
        break;
      case 'unavailable':
        txt = 'btn-danger';
        break;
      case 'pending':
        txt = 'btn-outline-warning';
        break;
      default:
        txt: 'btn-primary-outline';
    }
    return txt;
  }

  getFiles(id: string): Observable<JobFile[]> {
    return this.API.get<JobFile[]>(`jobs/${id}/files`);
  }

  addFile(id: string, filename: string, description: string): Observable<JobFile> {
    return this.API.post<JobFile>(`jobs/${id}/files`, {
      jobId: id,
      filename: String,
      descrpition: String,
    });
  }

  async uploadFile(id: string, filename: string, description: string, fileData: any) {
    // Generate new filename
    const ext = filename.split('.').reverse()[0];
    const newFilename = (new Date()).valueOf() + '.' + ext;

    // Get the pre-signed URL
    const url = await firstValueFrom(this.API.get<{ url: string }>(`shared/signed-url?fn=${newFilename}&method=PUT`));
    
    // Now post the file to the endpoint.
    await firstValueFrom(this.HTTP.put(url.url, fileData));

    // And now add the JobFile record
    return firstValueFrom(this.API.post(`jobs/${id}/files`, { jobId: id, filename: newFilename, description }));
  }

  deleteFile(jobId: string, fileId: string) {
    return this.API.delete(`jobs/${jobId}/files/${fileId}`);
  }
}
