<div class="client-contact-details">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Service Details</h4>
		<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
	</div>
	<div class="modal-body">
		<form [formGroup]="form">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" name="name" formControlName="name">
      </div>
      <div class="mb-3">
        <label for="pricingModel" class="form-label">Pricing Model</label>
        <select class="form-select" name="pricingModel" formControlName="pricingModel">
          <option value=""></option>
          <option value="full">Full</option>
          <option value="unit">Unit</option>
        </select>
      </div>
      <div class="mb-3" *ngIf="form.value.pricingModel === 'unit'">
        <label for="name" class="form-label">Unit</label>
        <input type="text" class="form-control" name="unit" formControlName="unit">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
      <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
      <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
      Save
    </button>
	</div>
</div>