import { Component } from '@angular/core';
import { Service } from '../service.class';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Skill } from '../../skills/skill.class';
import { SkillsDetailsComponent } from '../../skills/skills-details/skills-details.component';
import { SkillsService } from '../../skills/skills.service';
import { ServicesService } from '../services.service';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceDetailsComponent } from '../service-details/service-details.component';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrl: './services-list.component.scss'
})
export class ServicesListComponent extends JangoComponentBase {
  services$: Observable<Service[]>;
  constructor(private Auth: AuthService, private Services: ServicesService, private NgbModal: NgbModal,) {
    super(Auth);
    this.services$ = Services.findAll();
  }

  add() {
    const modalRef = this.NgbModal.open(
      ServiceDetailsComponent,
      { size: 'lg', centered: true }
    );
    
    modalRef.componentInstance.data = { name: '' };

    modalRef.result
      .then(() => {
        this.services$ = this.Services.findAll();
      })
      .catch(() => {

      })
  }

  edit(data: Service) {
    const modalRef = this.NgbModal.open(
      ServiceDetailsComponent,
      { size: 'lg', centered: true }
    );
    
    modalRef.componentInstance.data = data;
  
    modalRef.result
      .then(() => {
        this.services$ = this.Services.findAll();
      })
      .catch(() => {
  
      })
  }

}
