<div class="modal-header">
  <h4 class="modal-title">{{config?.title}}</h4>
</div>
<div class="modal-body">
  <p [ngClass]="{
      alert: config?.type,
      'alert-danger': config?.type === 'danger',
      'alert-warning': config?.type === 'warning',
      'alert-success': config?.type === 'success'
    }">{{config?.body}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">{{config?.closeText ?
    config?.closeText : 'Close'}}</button>
</div>
