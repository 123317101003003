<div class="container-xl" *ngIf="data$ | async as data">
  <h3>Rate Card Details</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label class="form-label">Name</label>
          <input type="text" class="form-control" formControlName="name">
        </div>
      </div>
    </div>
    <table class="table mb-5">
      <thead>
        <tr>
          <th>Service</th>
          <th>Per Hour/Unit</th>
          <th>Travel Hour</th>
          <th>Mileage</th>
        </tr>
      </thead>
      <tbody formArrayName="services">
        <tr *ngFor="let rate of services.controls; let i = index" [formGroupName]="i">
          <th class="align-middle">
            {{ rate.value.service }}
          </th>
          <td>
            <div class="input-group" *ngIf="rate.value.pricingModel === 'full'">
              <input type="number" class="form-control" formControlName="perHour">
              <span class="input-group-text">per hour</span>
            </div>
            <div class="input-group" *ngIf="rate.value.pricingModel === 'unit'">
              <input type="number" class="form-control" formControlName="perUnit">
              <span class="input-group-text">per {{ rate.value.unit }}</span>
            </div>
          </td>
          <td>
            <input type="number" class="form-control" formControlName="travelHour" type="number" *ngIf="rate.value.pricingModel === 'full'">
          </td>
          <td>
            <input type="number" class="form-control" formControlName="mileage" type="number" *ngIf="rate.value.pricingModel === 'full'">
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5">
            <select class="form-select" formControlName="service" (change)="serviceSelected()">
              <option value="">(Add service)</option>
              <option *ngFor="let s of services$ | async" [ngValue]="s">{{s.name}}</option>
            </select>
          </td>
        </tr>
      </tfoot>
    </table>

    <div class="mb-3 float-end">
      <a class="btn btn-outline-primary me-2" routerLink="/rate-cards">Cancel</a>
      <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
        <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
        <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
        Save
      </button>
    </div>
  </form>
</div>