import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const requiredPermission = route.data['permission'] || '';

    const redirectUrl = encodeURIComponent(state.url);

    if (this.authService.decodedToken) {
      // if anything but false, proceed to page
      if (!this.authService.decodedToken || !this.authService.decodedToken.permissions) {
        return true; // proceed to page
      } else {
        if (this.hasRoutePermission(requiredPermission)) {
          return true; // proceed to page
        } else {
          console.error('Account does not have permission to view this page');
          const tree: UrlTree = this.router.parseUrl(this.router.url);
          return tree;
        }
      }
    } else {
      this.authService.currentUser$.next(null);
      const tree: UrlTree = this.router.parseUrl('/login?redir=' + redirectUrl);
      return tree;
    }
  }

  hasRoutePermission(requiredPermission: string[]) {
    let hasPermission = false;

    if (this.authService.decodedToken.permissions.includes('odin')) {
      hasPermission = true;
      return hasPermission;
    }

    for (const key of requiredPermission) {
      for (const u of this.authService.decodedToken.permissions) {
        hasPermission = u.includes(key);
        if (hasPermission) break;
      }

      if (hasPermission) break;

      return hasPermission;
    }
    return hasPermission;
  }
}
