import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../guards/auth-guard.service';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientDetailsComponent } from './client-details/client-details.component';

const routes: Routes = [
  {
    path: 'clients',
    data: { permission: ['clients'] },
    component: ClientsListComponent
  },
  {
    path: 'clients/create',
    data: { permission: ['clients'] },
    component: ClientDetailsComponent
  },
  {
    path: 'clients/:id',
    data: { permission: ['clients'] },
    component: ClientDetailsComponent
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
