import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { environment } from "../../environments/environment";
import { ApiService } from "../shared/api/api.service";
import jwt_decode from 'jwt-decode';
import { IUser } from "./user.interface";
import { AuthModule } from "./auth.module";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _emptyUser: IUser = {
    email: '',
    firstName: '',
    lastName: '',
    verified: false,
    disabled: false,
    lastLogin: null,
    token: null,
    permissions: []
  };
  currentUser$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(this._emptyUser);
  token: string = '';
  refreshToken: string = '';
  token$: BehaviorSubject<string> = new BehaviorSubject('');
  decodedToken: any = null;
  private _user: IUser | null = null;

  constructor(private API: ApiService, private router: Router) {
    if (localStorage.getItem(`${environment.appName}-currentUser`)) {
      const user = JSON.parse(localStorage.getItem(`${environment.appName}-currentUser`) || '');
      const accessToken = this.getToken();
      const refreshToken = this.getRefreshToken();
      if (user && accessToken && refreshToken) {
        this.decodedToken = jwt_decode(accessToken);

        this._user = user;

        this.currentUser$.next(this._user);
      } else {
        this.currentUser$.next(null);
      }
    } else {
      this.currentUser$.next(null);
    }
  }

  async login
    (loginData: object | null | undefined) {
    let result: any = await this.API.post(`auth/login`, loginData).toPromise();
    localStorage.setItem(`${environment.appName}-currentUser`, JSON.stringify(result.user));
    this.setAccessToken(result.tokenObj.accessToken);
    this.setRefreshToken(result.tokenObj.refreshToken);
    this.decodedToken = jwt_decode(result.tokenObj.accessToken);
    this.currentUser$.next(result.user);
    return result.user;
  }

  public setAccessToken(accessToken: string) {
    this.token = accessToken;
    window.localStorage.setItem(`${environment.appName}-token`, accessToken);
    this.token$.next(accessToken);
  }

  public setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
    window.localStorage.setItem(`${environment.appName}-refresh-token`, refreshToken);
  }

  getToken() {
    return localStorage.getItem(`${environment.appName}-token`) || '';
  }

  getRefreshToken() {
    return localStorage.getItem(`${environment.appName}-refresh-token`) || '';
  }

  logout(): void {
    this.token = '';
    this.currentUser$.next(null);
    window.localStorage.removeItem(`${environment.appName}-currentUser`);
    window.localStorage.removeItem(`${environment.appName}-token`);
    window.localStorage.removeItem(`${environment.appName}-refresh-token`);
    this.token$.next('');
    this.router.navigateByUrl('/login');
  }

  getPermissions() {
    return this.API.get(`auth/permissions`);
  }

  hasPermission(permissions: string[]): boolean {
    let hasPermission = false;
    if (!this.decodedToken) return false;

    if (this.decodedToken.permissions.includes('odin')) {
      hasPermission = true;
    } else {
      for (const key of permissions) {
        for (const u of this.decodedToken.permissions) {
          hasPermission = u.includes(key);
          if (hasPermission) break;
        }
  
        if (hasPermission) break;
  
        return hasPermission;
      }
    }

    return hasPermission;
  }

  passwordsComplex(password: string, password2: string): Boolean {
    let score = 0;
  
    if (password.length < 8 || password2.length < 8) {
      return false;
    }
  
    // The password must contain 3 of the following conditions
    if (/[a-z]/.test(password)) {
      score++;
    }
    if (/[A-Z]/.test(password)) {
      score++;
    }
    if ((/[0-9]/).test(password)) {
      score++;
    }
  
    const charList = '!@#$%^&*()_+~-=\\`{}[]:\";<>?,./';
    for (const c of charList) {
      if (password.indexOf(c) > -1) {
        score++;
        break;
      }
    }
  
    if (score >= 3) {
      return true;
    }
    return false;
  }
}