import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api/api.service';
import { JangoBaseService } from '../shared/jango-base.service';
import { Service } from './service.class';

@Injectable({
  providedIn: 'root'
})
export class ServicesService extends JangoBaseService<Service> {
  override endpoint = 'services';

  constructor(override API: ApiService) {
    super(API);
  }
}