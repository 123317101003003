import { Component, Input, OnInit } from '@angular/core';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { JobsService } from '../jobs.service';
import { Observable } from 'rxjs';
import { JobOfferView } from '../job-offer-view.interface';
import { JobOfferSearchResult } from '../job-offer-search-result.interface';
import { Address } from '../../shared/address.class';

@Component({
  selector: 'app-job-offers-list',
  templateUrl: './job-offers-list.component.html',
  styleUrl: './job-offers-list.component.scss'
})
export class JobOffersListComponent extends JangoComponentBase implements OnInit {
  @Input() mode: string = '';

  offers$: Observable<JobOfferSearchResult[]> | null = null;
  constructor(private Auth: AuthService, private Jobs: JobsService) {
    super(Auth);
  }

  ngOnInit() {
    this.getData(this.mode);
  }

  getData(mode: string) {
    if (mode === 'offers') {
      this.offers$ = this.Jobs.getMyOffers();
    } else {
      this.offers$ = this.Jobs.getMyAssignments();
    }
  }

  getLatLong(addr: Address): string {
    let ll = '';
    if (addr && addr.geo && addr.geo.coordinates) {
      ll = `${addr.geo.coordinates[1]},${addr.geo.coordinates[0]}`;
    }
    return ll;
  }

  getOfferClass = this.Jobs.getOfferClass;
}
