import { IGenericLookup } from "./generic-lookup.interface";

function EnumToArray(T: any): Array<IGenericLookup> {
  const arr = Object.keys(T)
    .filter((key) => isNaN(Number(key))) // Filter out numeric keys
    .map((key) => {
      return {
        key,
        value: T[key as keyof typeof T],
      };
    });

  return arr;
}

export { EnumToArray };
