<div class="verify">
  <h2 i18n="@@setPassword">Set Your Password</h2>
  <div class="row justify-content-center">
    <div class="col-4">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
        <div class="form-floating">
          <input id="password" type="password" formControlName="password" class="form-control" placeholder="Password">
          <label for="password" class="form-label">Password: </label>
        </div>

        <div class="form-floating">
          <input id="confirmPassword" type="password" formControlName="confirmPassword" class="form-control"
            placeholder="Confirm Password">
          <label for="confirmPassword" class="form-label">Password: </label>
        </div>
        <hr>
        <button class="btn btn-primary" style="width: 100%;" type="submit" [disabled]="!resetPasswordForm.valid">Set
          Password</button>
      </form>
    </div>
  </div>
</div>
