import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { JangoComponentBase} from '../shared/jango-base.class';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends JangoComponentBase implements OnInit {
  constructor(protected override authService: AuthService) {
    super(authService);
  }

  ngOnInit(): void {
  }

  async test() {
    // await this.userService.test();
  }

  async profile() {
    // await this.userService.profile();
  }

  logout() {
    this.authService.logout();
  }
}
