<div>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Language</th>
        <th class="d-none d-md-table-cell">Preferred Interpreter</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let l of skills$ | async">
        <td scope="row">
          <a href="javascript: void(0)" (click)="editSkill(l)">
            {{ l.name }}
          </a>
        </td>
        <td class="d-none d-md-table-cell">{{ l.preferredInterpreterName }}</td>
      </tr>
    </tbody>
  </table>
  <button class="btn btn-primary" (click)="addSkill()">
    <fa-icon [icon]="Icons.newRecord"></fa-icon>
    Add
  </button>
</div>