import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { IUser } from '../auth/user.interface';
import { JangoComponentBase} from '../shared/jango-base.class';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent extends JangoComponentBase implements OnInit {

  isMenuCollapsed = true;
  currentUser: any = [];

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
    this.isMenuCollapsed = true;
  }
}
