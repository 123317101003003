import { Component } from '@angular/core';
import { BehaviorSubject, Observable, finalize, firstValueFrom, map, of, startWith, switchMap, tap } from 'rxjs';
import { Client, ClientRate } from '../client.class';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { ClientsService } from '../clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IGenericLookup } from '../../shared/generic-lookup.interface';
import { ClientContact } from '../client-contact.class';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientContactDetailsComponent } from '../client-contact-details/client-contact-details.component';
import { SharedService } from '../../shared/shared.service';
import { Service } from '../../services/service.class';
import { ServicesService } from '../../services/services.service';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent extends JangoComponentBase {
  id: string = '';
  data$: Observable<Client>;
  contacts$!: Observable<ClientContact[]>;
  // specialisms$: Observable<IGenericLookup[]>;
  statuses$: Observable<IGenericLookup[]>;
  types$: Observable<IGenericLookup[]>;
  services$: Observable<Service[]>;
  form: FormGroup;
  saving: boolean = false;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNew = true;

  constructor(private  Auth: AuthService, private Clients: ClientsService, private Services: ServicesService, private Router: Router, private ActiveRoute: ActivatedRoute, private fb: ClassValidatorFormBuilderService, private ngFB: FormBuilder, private NgbModal: NgbModal) {
    super(Auth);

    this.form = this.fb.group(Client, {
      _id: [''],
      name: [''],
      code: [''],
      type: [''],
      // specialism: [''],
      notes: [''],
      status: [true],
      invoicingMode: [''],
      // rates: this.fb.array([])
    });

    // this.specialisms$ = this.Clients.specialisms$;
    this.statuses$ = this.Clients.statuses$;
    this.services$ = this.Services.findAll();
    this.types$ = this.Clients.getClientTypes();

    this.data$ = this.ActiveRoute.params.pipe(
      tap((params) => this.id = params['id']),
      tap((params) => this.contacts$ = this.getContacts()),
      switchMap((params) => this.getData(params['id'])),
      tap((data) => this.populateForm(data)),
      tap((data) => this.isNew = !data._id || data._id === 'create' ),
    );

    // this.contacts$ = this.ActiveRoute.params.pipe(
    //   switchMap((params) => this.getContacts())
    // );

    // this.saving$.pipe(
    //   switchMap(() => this.Clients.save(this.form.value)),
    //   tap((data) => this.Router.navigate(['clients', data._id]))
    // )
  }

  // addRate(rate?: ClientRate) {
  //   this.rates.push(this.fb.group(ClientRate, {
  //     service: [rate?.service || ''],
  //     firstHour: [rate?.firstHour || 0],
  //     subsequentHour: [rate?.subsequentHour || 0],
  //     travelHour: [rate?.travelHour || 0],
  //     mileage: [rate?.mileage || 0],
  //   }));
  // }

  // get rates() {
  //   return this.form.get('rates') as FormArray;
  // }

  getData(id: string =  ''): Observable<Client> {
    return this.Clients.findById(id);
  }

  getContacts(): Observable<ClientContact[]> {
    return this.Clients.getContacts(this.id);
  }

  populateForm(data: Client) {
    this.form.patchValue(data);
    // (data.rates || []).forEach( r => this.addRate(r));
  }

  save() {
    this.saving$.next(true);
    this.data$ = this.Clients.save({ ...this.form.value }).pipe(
      tap(() => this.saving$.next(false) ),
      // tap((data) => this.Router.navigate(['clients'])),
    );
  }

  cancel() {
    this.Router.navigate(['clients']);
  }

  addContact() {
    const modalRef = this.NgbModal.open(
      ClientContactDetailsComponent,
      { size: 'lg', centered: true }
    );
    
    modalRef.componentInstance.clientId = this.id;

    modalRef.result
      .then((result) => {
        this.contacts$ = this.getContacts();
      })
      .catch(() => {

      })
  }

  editContact(c: ClientContact) {
    const modalRef = this.NgbModal.open(
      ClientContactDetailsComponent,
      { size: 'lg', centered: true }
    );

    modalRef.componentInstance.clientId = this.id;
    modalRef.componentInstance.data = c;

    modalRef.result.then((result) => {
      this.contacts$ = this.getContacts();
    });

    modalRef.result.catch(() => {

    });
  }
}
