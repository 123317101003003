<div class="client-contact-details">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Language Details</h4>
		<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
	</div>
	<div class="modal-body">
		<form [formGroup]="form">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" formControlName="name" [readonly]="data._id">
      </div>
      <div class="mb-3" *ngIf="data._id">
        <label for="preferredInterpreterName" class="form-label">Preferred Interpreter</label>
        <input id="preferredInterpreter" type="text"
         name="preferredInterpreter"
         class="form-control"
         formControlName="preferredInterpreter"
         [ngbTypeahead]="search"  
         [resultFormatter]="resultFormatter"
         [inputFormatter]="inputFormatter"
         (selectItem)="selectItem($event)"
         [editable]="false"
         #instance="ngbTypeahead"
         />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
      <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
      <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
      Save
    </button>
	</div>
</div>