import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { Job } from '../job.class';
import { BehaviorSubject, Observable, firstValueFrom, of, switchMap, tap } from 'rxjs';
import { Client } from '../../clients/client.class';
import { ClientsService } from '../../clients/clients.service';
import { ClientContact } from '../../clients/client-contact.class';
import { NgbDateAdapter, NgbDateParserFormatter, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../shared/ngtime-adapter.class';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/ngdate-adapter.class';
import { SharedService } from '../../shared/shared.service';
import { IGenericLookup } from '../../shared/generic-lookup.interface';
import { JobsService } from '../jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Interpreter } from '../../interpreters/interpreters.class';
import { Address } from '../../shared/address.class';
import { ModalService } from '../../shared/modal/modal.service';
import { Skill } from '../../skills/skill.class';
import { SkillsService } from '../../skills/skills.service';
import { Service } from '../../services/service.class';
import { ServicesService } from '../../services/services.service';
import { RateCardsService } from '../../rate-cards/rate-cards.service';
import { RateCard } from '../../rate-cards/rate-card.class';
import { IJobPriceRequest } from '../job-price-request.interface';
import { JobOfferView } from '../job-offer-view.interface';
import { environment } from '../../../environments/environment';
import { JobFile } from '../job-file.class';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrl: './job-details.component.scss',
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class JobDetailsComponent extends JangoComponentBase {
  form: FormGroup;
  data$: Observable<Job>;
  clients$: Observable<Client[]>;
  contacts$: Observable<ClientContact[]>;
  services$: Observable<Service[]>;
  languages$: Observable<Skill[]>;
  rateCards$: Observable<RateCard[]>;
  offers$: Observable<any[]>;
  files$!: Observable<JobFile[]>;
  // interpreters$: Observable<Interpreter[]>;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNew = false;
  id: string = '';
  fileData: any;
  fileForm: FormGroup;
  

  constructor(private Auth: AuthService, private Clients: ClientsService, private Jobs: JobsService, private RateCards: RateCardsService, private Services: ServicesService, private Skills: SkillsService, private Shared: SharedService, private ActiveRoute: ActivatedRoute, private Router: Router, private fb: ClassValidatorFormBuilderService, private Modal: ModalService, private ngFB: FormBuilder) {
    super(Auth);

    this.form = this.fb.group(Job, {
      _id: [''],
      jobNo: [''],
      jobNoNumeric: [''],
      clientId: [''],
      clientContactId: [''],
      date: [''],
      endClient: [''],
      durationRequested: 0,
      durationBooked: 0,
      serviceId: [''],
      pricingModel: [''],
      unit: [''],
      units: [0],
      language: [''],
      startTime: ['00:00'],
      endTime: ['00:00'],
      clientEndTime: ['00:00'],
      notes: [''],
      price: [0],
      adjustment: [0],
      customerReference: [''],
      attendingProfessional: [''],
      parentReference: [''],
      rateCardId: [''],
      address: this.fb.group(Address, {
        line1: [''],
        line2: [''],
        city: [''],
        county: [''],
        postcode: [''],
        country: [''],
      }),
    });

    this.fileForm = this.ngFB.group({
      fileDescription: ['', [Validators.required]],
      selectedFile: ['', [Validators.required]]
    });

    this.data$ = of({} as Job);
    this.clients$ = this.Clients.findAll();
    this.services$ = this.Services.findAll();
    this.languages$ = Skills.findAll();
    this.rateCards$ = RateCards.findAll();
    this.contacts$ = of([]);
    this.offers$ = of([]);

    this.data$ = this.ActiveRoute.params.pipe(
      tap((params) => this.id = params['id']),
      switchMap((params) => this.getData(params['id'])),
      tap((data) => this.populateForm(data)),
      tap((data) => this.getContacts()),
      tap((data) => this.getOffers(this.id)),
      tap((data) => this.isNew = !data._id || data._id === 'create' ),
      tap((data) => this.files$ = this.Jobs.getFiles(data._id!))
    );
  }

  getData(id: string =  ''): Observable<Job> {
    let job: Job = {
      // _id: '',
      jobNo: '',
      parentReference: '',
      clientId: '',
      clientName: '',
      clientContactId: '',
      interpreterId: '',
      interpreterName: '',
      durationRequested: 0,
      durationBooked: 0,
      date: '',
      service: '',
      serviceId: '',
      pricingModel: '',
      unit: '',
      units: 0,
      customerReference: '',
      startTime: '',
      endTime: '',
      language: '',
      endClient: '',
      notes: '',
      _createdBy: '',
      _createdOn: null,
      _updatedBy: '',
      _updatedOn: null,
      attendingProfessional: '',
      address: new Address(),
      price: 0,
      fee: 0,
      adjustment: 0,
      status: '',
      rateCardId: '',
    };
    if (!id) return of(job);
    return this.Jobs.findById(id);
  }

  populateForm(data: Job) {
    this.form.patchValue(data);
    // this.services$.subscribe((services) => {
    //   if (this.form.value.serviceId) {
    //     const selectedService = services.find(s => s._id === this.form.value.serviceId);
    //     this.form.patchValue({ serviceId: selectedService }); // Use patchValue to set the default
    //   }
    // });
    // (data.rates || []).forEach( r => this.addRate(r))
  }

  getContacts() {
    if (!this.form.value.clientId) return;
    this.contacts$ = this.Clients.getContacts(this.form.value.clientId);
  }

  setAddress() {
    if (!this.form.value.address.line1) {

    }
  }

  serviceSelected(event: any) {
    const selectedOption = event.target.selectedOptions[0];
    const pricingModel = selectedOption.getAttribute('pricingModel');
    const unit = selectedOption.getAttribute('unit');

    this.form.patchValue({ pricingModel, unit });
  }

  getOffers(id: string) {
    this.offers$ = this.Jobs.getOffers(id);
  }

  async sendOffer(interpreterId: string, fee: number = 0) {
    await firstValueFrom(this.Jobs.sendOffer(this.id, interpreterId, fee));
    this.getOffers(this.id);
  }

  async assignInterpreter(offer: any) {
    await firstValueFrom(this.Jobs.assignInterpreter(this.id, offer._id));
    this.getOffers(this.id);
  }

  async unassignInterpreter(offer: any) {
    await firstValueFrom(this.Jobs.unassignInterpreter(this.id, offer._id));
    this.getOffers(this.id);
  }

  async save() {
    this.data$ = this.Jobs.save({ ...this.form.value }).pipe(
      tap(() => this.saving$.next(false) ),
      tap((data) => this.Router.navigate(['jobs'])),
    );
  }

  async getClientPrice() {
    const data = this.form.value;
    const dto: IJobPriceRequest = {
      serviceId: data.serviceId,
      rateCardId: data.rateCardId,
      units: data.pricingModel == 'full' ? data.durationBooked : data.units,
      mileage: 0,
      travelHours: 0
    };
    const price = await firstValueFrom(this.Jobs.getClientPrice(dto));
    this.form.patchValue({ price: price.base });
  }

  trimNotes(notes: string = '') {
    const len = 30;
    let _notes = notes.substring(0, len);
    if (notes.length > len) {
      _notes += '...';
    }
    return _notes;
  }

  isPriceDisabled() {
    return (!this.form.value.serviceId || !this.form.value.rateCardId);
  }

  canAssignOffer(o: any) {
    if (o.assigned) return false;

    return ['available', 'tentative'].includes(o.status);
  }

  getOfferIcon(status: string) {
    let icon;

    switch (status) {
      case 'available':
        icon = this.Icons.accepted;
        break;
      case 'tentative':
        icon = this.Icons.tentative;
        break;
      case 'unavailable':
        icon = this.Icons.declined;
        break;
      case 'pending':
      default:
        icon = this.Icons.email;
        break;
    }

    return icon;
  }

  getOfferText(status: string) {
    let txt = '';
    switch (status) {
      case 'available':
        txt = 'Available';
        break;
      case 'tentative':
        txt = 'Tentative';
        break;
      case 'unavailable':
        txt = 'Unavailable';
        break;
      case 'pending':
        txt = 'Pending';
        break;
      default:
        text: '';
    }
    return txt;
  }

  async acceptOffer(o: JobOfferView) {
    await firstValueFrom(this.Jobs.acceptOffer(o.offerId || '', ''));
    this.getOffers(this.id);
  }

  async tentativeOffer(o: JobOfferView) {
    await firstValueFrom(this.Jobs.tentativeOffer(o.offerId || '', ''));
    this.getOffers(this.id);
  }

  async rejectOffer(o: JobOfferView) {
    await firstValueFrom(this.Jobs.declineOffer(o.offerId || '', ''));
    this.getOffers(this.id);
  }

  pdf() {
    window.open(environment.apiURL + `jobs/${this.id}/pdf?t=${this.Auth.token}`);
  }

  onFileSelected(event: any): void {
    this.fileData = event.target.files[0];
    // this.uploadFile(fileToUpload.items(0));
  }

  async uploadFile() {
    function _stripFilename(fn: string) {
      return fn.substring(fn.lastIndexOf("\\") + 1, fn.length); 
    }

    await this.Jobs.uploadFile(this.id, _stripFilename(this.fileForm.value.selectedFile), this.fileForm.value.fileDescription, this.fileData);
    this.form.patchValue({ selectedFile: '' });
    this.files$ = this.Jobs.getFiles(this.id);
  }

  async downloadFile(fn: string) {
    const url: {url: string} = await firstValueFrom(this.Shared.getPreSignedURLForFile(fn, 'GET'));
    window.open(url.url);
  }

  async deleteFile(id: string) {
    await firstValueFrom(this.Jobs.deleteFile(this.id, id));
    this.files$ = this.Jobs.getFiles(this.id);
  }

  getOfferClass = this.Jobs.getOfferClass;
}
