<div class="dashboard">
  <h3 *ngIf="currentUser$ | async as currentUser">
    Your Dashboard
  </h3>

  <ul ngbNav #nav="ngbNav" class="nav-tabs">  
    <li [ngbNavItem]="1">
      <button ngbNavLink>Assignments</button>
      <ng-template ngbNavContent>
        <app-job-offers-list [mode]="'assignments'"></app-job-offers-list>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <button ngbNavLink>Offers</button>
      <ng-template ngbNavContent>
        <app-job-offers-list [mode]="'offers'"></app-job-offers-list>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
