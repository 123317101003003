import { Component } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { JangoComponentBase} from '../../shared/jango-base.class';
import { AuthService } from '../auth.service';

interface IUser {
  username: string;
  password: string;
}

interface IUserFormGroup extends FormGroup {
  value: IUser;

  // We need to add these manually again, same fields as IUser
  controls: {
    username: AbstractControl;
    password: AbstractControl;
  };
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends JangoComponentBase{
  loginFailed = false;
  loginForm: IUserFormGroup;

  constructor(protected override authService: AuthService, private router: Router, private formBuilder: FormBuilder) {
    super(authService);
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    }) as IUserFormGroup;
  }

  async login() {
    try {
      this.loginFailed = false;
      const loginData: IUser = { username: this.loginForm.value.username, password: this.loginForm.value.password };
      await this.authService.login(loginData);
      this.router.navigateByUrl('/dashboard');
    } catch (e) {
      this.loginFailed = true;
    }
  }
}
