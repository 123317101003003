<div class="register">
  <h2>Register</h2>
  <div class="alert alert-danger" role="alert" *ngIf="registerFailed" i18n="@@registerFailedMsg">
    Unable to register, Please try again.
  </div>
  <div class="row justify-content-center">
    <div class="col-4">
      <form [formGroup]="registerForm" (ngSubmit)="register()">
        <div class="form-floating">
          <input id="email" type="text" formControlName="email" class="form-control" placeholder="Email Address">
          <label for="email" class="form-label">Email Address: </label>
        </div>

        <div class="form-floating">
          <input id="firstName" type="text" formControlName="firstName" class="form-control" placeholder="First Name">
          <label for="firstName" class="form-label">First Name: </label>
        </div>

        <div class="form-floating">
          <input id="lastName" type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
          <label for="lastName" class="form-label">Last Name: </label>
        </div>

        <hr>
        <button class="btn btn-primary" style="width: 100%;" type="btn"
          [disabled]="!registerForm.valid">Register</button>
      </form>
    </div>
  </div>
</div>
