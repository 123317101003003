import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api/api.service';
import { JangoBaseService } from '../shared/jango-base.service';
import { RateCard } from './rate-card.class';

@Injectable({
  providedIn: 'root'
})
export class RateCardsService extends JangoBaseService<RateCard> {
  override endpoint = 'rate-cards';

  constructor(override API: ApiService) {
    super(API);
  }
}