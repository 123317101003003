import { IsOptional, IsString, IsNumber, Min, ValidateNested, MinLength } from 'class-validator';
import { JangoMongooseBase } from '../shared/jango-mongoose-base';

export class RateCardLine {
  @IsString()
  serviceId: string = '';

  @IsString()
  service: string = '';

  @IsString()
  unit: string = '';

  @IsString()
  pricingModel: 'full' | 'unit' = 'full';

  @IsNumber()
  @Min(0)
  perHour: number = 0;

  @IsNumber()
  @Min(0)
  mileage: number = 0;

  @IsNumber()
  @Min(0)
  travelHour: number = 0;

  @IsNumber()
  @Min(0)
  perUnit: number = 0;
}

export class RateCard extends JangoMongooseBase {
  @IsString()
  @MinLength(1)
  name: string = '';

  @ValidateNested({ each: true })
  services: RateCardLine[] = [];
}