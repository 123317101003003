import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { GenericModalComponent } from 'src/app/shared/modal/generic-modal/generic-modal.component';
import { AuthService } from '../../auth/auth.service';
import { JangoComponentBase} from '../../shared/jango-base.class';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-maintenance-detail',
  templateUrl: './user-maintenance-detail.component.html',
  styleUrls: ['./user-maintenance-detail.component.scss']
})
export class UserMaintenanceDetailComponent extends JangoComponentBase implements OnInit {
  @Input() userId: any = null;
  loading = false;
  apiPermissions: any = {};
  user: any = {};
  filteredPermissions: any;
  userMainForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    permissions: new FormControl([])
  });

  constructor(protected override authService: AuthService, private usersService: UsersService, public activeModal: NgbActiveModal, private ngbModal: NgbModal) {
    super(authService);
  }

  async ngOnInit() {
    this.apiPermissions = await this.authService.getPermissions().toPromise();
    if (this.userId) {
      this.user = await this.usersService.findUserById(this.userId).toPromise();
      this.userMainForm = new FormGroup({
        email: new FormControl(this.user.email, [Validators.required, Validators.email]),
        firstName: new FormControl(this.user.firstName, Validators.required),
        lastName: new FormControl(this.user.lastName, Validators.required),
        permissions: new FormControl(this.user.permissions),
      });
    } else {
      this.user = {
        permissions: []
      };
      this.userMainForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        permissions: new FormControl([]),
      });
    }
    this.checkPermissions();
  }

  checkPermissions() {
    if (this.userId) {
      this.filteredPermissions = this.apiPermissions.filter((f: any) => {
        if (!this.user.permissions.includes(f.value)) {
          return f;
        }
      });
    } else {
      this.filteredPermissions = this.apiPermissions;
    }
  }

  addPermission(permission: any) {
    this.user.permissions.push(permission.value);
    const index = this.filteredPermissions.indexOf(permission);
    this.filteredPermissions.splice(index, 1);
  }

  removePermission(index: any) {
    this.user.permissions.splice(index, 1);
    this.checkPermissions();
  }

  async disableEnableUser(disable: boolean) {
    try {
      this.loading = true;
      await this.usersService.disableEnableUser(this.userId, disable);
      const modalRef = this.ngbModal.open(GenericModalComponent);
      modalRef.componentInstance.config = {
        title: 'Successful.',
        body: disable ? 'Successfully disabled user.' : 'Successfully reinstated user.',
        closeText: 'OK'
      };
      this.activeModal.close();
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  checkPermission() {
    if (!!this.userId) {
      return this.authService.hasPermission(['user', 'user-update']);
    } else {
      return this.authService.hasPermission(['user', 'user-create']);
    }
  }

  async resetPassword(email: string) {
    try {
      this.loading = true;
      await this.usersService.requestNewPassword(email);
      const modalRef = this.ngbModal.open(GenericModalComponent);
      modalRef.componentInstance.config = {
        title: 'Email sent.',
        body: 'Password reset email sent.',
        closeText: 'OK'
      };
      this.activeModal.close();
      this.loading = false;
    } catch (err) {
      const modalRef = this.ngbModal.open(GenericModalComponent);
      modalRef.componentInstance.config = {
        title: 'Failed Password Reset.',
        body: 'Reset Password email not sent',
        closeText: 'OK'
      };
      this.loading = false;
    }
  }

  getButtonText() {
    if (this.userId) {
      return 'Update';
    } else {
      return 'Create';
    }
  }

  async createUpdate() {
    try {
      this.loading = true;
      let user: any = this.userMainForm;
      user.permissions = this.user.permissions;
      if (!!this.userId) {
        await this.usersService.updateUser(this.userId, this.userMainForm.value.email, this.userMainForm.value.firstName, this.userMainForm.value.lastName, user.permissions).toPromise();
        const modalRef = this.ngbModal.open(GenericModalComponent);
        modalRef.componentInstance.config = {
          title: 'Successful.',
          body: 'Successfully updated user.',
          closeText: 'OK'
        };
        this.activeModal.close();
        this.loading = false;
      } else {
        await this.usersService.createUser(this.userMainForm.value.email, this.userMainForm.value.firstName, this.userMainForm.value.lastName, user.permissions).toPromise();
        const modalRef = this.ngbModal.open(GenericModalComponent);
        modalRef.componentInstance.config = {
          title: 'Successful.',
          body: 'Successfully created new user.',
          closeText: 'OK'
        };
        this.activeModal.close();
        this.loading = false;
      }
    } catch (e) {
      this.loading = false;
    }
  }

  cancel() {
    this.activeModal.close();
  }
}
