import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InterpretersService } from '../../interpreters/interpreters.service';
import { Interpreter } from '../../interpreters/interpreters.class';
import { JangoPagedQueryResponse } from '../../shared/jango-paged-query-response.class';
import { Service } from '../service.class';
import { ServicesService } from '../services.service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.scss'
})
export class ServiceDetailsComponent extends JangoComponentBase implements OnInit {
  @Input() data: Service = {
    name: '',
    unit: '',
    pricingModel: '',
    _createdBy: '',
    _createdOn: null,
    _updatedBy: '',
    _updatedOn: null
  }
  form: FormGroup;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // interpreters$: Observable<Interpreter[]>;
  interpretersList: Interpreter[] = [];

  constructor(private Auth: AuthService, private Services: ServicesService, private Interpreters: InterpretersService, private fb: ClassValidatorFormBuilderService, private modalRef: NgbActiveModal) {
    super(Auth);

    this.form = fb.group(Service, {
      _id: [''],
      name: [Validators.required, ''],
      pricingModel: [''],
      unit: ['']
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.form.patchValue(this.data);
    }
  }

  async save() {
    const formData = this.form.value;
    this.saving$.next(true);
    // if (!this.data._id) delete postData._id;
    await firstValueFrom(this.Services.save(formData));
    this.modalRef.close();
  }

  cancel() {
    this.modalRef.dismiss('');
  }

}
