import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericModalComponent } from '../shared/modal/generic-modal/generic-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../shared/modal/error-modal/error-modal.component';

@Injectable()
export class ErrorInterceptorInterceptor implements HttpInterceptor {
  @Output() errorDialog = new EventEmitter<any>();

  constructor(private ngbModal: NgbModal) {

  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            /*if (err.url.includes('/PAGE NAME')) {
              return;
            }*/

            const modalRef = this.ngbModal.open(ErrorModalComponent);

            modalRef.componentInstance.message = err.error.message || 'There was a problem, Please refresh your browser.';
          } else if (err.status === 403) {
            const modalRef = this.ngbModal.open(ErrorModalComponent);

            modalRef.componentInstance.message = err.error.message || 'There was a problem, Please refresh your browser.';
          }
        }
      })
    );
  }
}
