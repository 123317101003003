import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicesRoutingModule } from './services-routing.module';
import { ServicesListComponent } from './services-list/services-list.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';



@NgModule({
  declarations: [ServicesListComponent, ServiceDetailsComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ServicesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
  ]
})
export class ServicesModule { }
