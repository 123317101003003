import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JangoComponentBase} from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent extends JangoComponentBase implements OnInit {
  newPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  validationfailed = false;
  emailSent = false;

  constructor(protected override authService: AuthService, private userService: UsersService, private router: Router) {
    super(authService);
  }

  ngOnInit(): void {
  }

  get email() {
    return this.newPasswordForm.get('email') || '';
  }

  async requestNewPassword() {
    this.validationfailed = false;
    try {
      await this.userService.requestNewPassword(this.newPasswordForm.value.email).toPromise();
      this.emailSent = true;
    } catch (e) {
      this.validationfailed = true;
    }
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }
}
