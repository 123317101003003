import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterpretersListComponent } from './interpreters-list/interpreters-list.component';
import { InterpretersRoutingModule } from './interpreters-routing.module';
import { InterpreterDetailsComponent } from './interpreter-details/interpreter-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InterpretersService } from './interpreters.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StarRatingModule } from 'angular-star-rating';



@NgModule({
  declarations: [
    InterpretersListComponent,
    InterpreterDetailsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    InterpretersRoutingModule,
    StarRatingModule.forRoot()
  ],
  providers: [
    InterpretersService
  ]
})
export class InterpretersModule { }
