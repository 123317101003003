import { Component, inject, TemplateRef } from '@angular/core';
import { Observable, firstValueFrom, switchMap, tap } from 'rxjs';
import { Job } from '../job.class';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { JobsService } from '../jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobOfferView } from '../job-offer-view.interface';
import { JobOfferSearchResult } from '../job-offer-search-result.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '../../shared/address.class';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobFile } from '../job-file.class';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-job-offer-details',
  templateUrl: './job-offer-details.component.html',
  styleUrl: './job-offer-details.component.scss'
})
export class JobOfferDetailsComponent extends JangoComponentBase {
  form: FormGroup;
  job$: Observable<JobOfferView>;
  files$!: Observable<JobFile[]>;
  id: string = '';
  modalRef: any;
  fileForm: FormGroup;
  fileData: any;

  constructor(Auth: AuthService, private Jobs: JobsService, private ActiveRoute: ActivatedRoute, private modalService: NgbModal, private Router: Router, private fb: FormBuilder, private ngFB: FormBuilder, private Shared: SharedService) {
    super(Auth);

    this.form = this.fb.group({
      notes: [''],
      fee: [0, [Validators.required, Validators.min(1)]],
      durationCompleted: [0],
      mileage: [0],
      expenses: [0],
    });

    this.fileForm = this.ngFB.group({
      fileDescription: ['', [Validators.required]],
      selectedFile: ['', [Validators.required]]
    });

    this.job$ = this.ActiveRoute.params.pipe(
      tap((params) => this.id = params['id']),
      switchMap((params) => this.getData(params['id'])),
      tap((data) => this.files$ = this.Jobs.getFiles(data._id!))
    );
  }

  getData(id: string) {
    return this.Jobs.getJobOffer(id);
  }

  async acceptOffer(id: string) {
    await firstValueFrom(this.Jobs.acceptOffer(id, this.form.value.notes || '', this.form.value.fee));
    this.modalRef.close();
    this.Router.navigateByUrl('/dashboard');
  }

  async declineOffer(id: string) {
    await firstValueFrom(this.Jobs.declineOffer(id, this.form.value.notes || '', this.form.value.fee));
    this.modalRef.close();
    this.Router.navigateByUrl('/dashboard');
  }

  async tentativeOffer(id: string) {
    await firstValueFrom(this.Jobs.tentativeOffer(id, this.form.value.notes || '', this.form.value.fee));
    this.modalRef.close();
    this.Router.navigateByUrl('/dashboard');
  }

  async completeJob(id: string) {
    await firstValueFrom(this.Jobs.completeJob(id, { durationCompleted: this.form.value.durationCompleted, mileage: this.form.value.mileage, expenses: this.form.value.expenses }));
    this.Router.navigateByUrl('/dashboard');
  }

  isRespondedTo(status: string) {
    return ['available', 'tentative', 'unavailable'].indexOf(status) > -1;
  }

  getLatLong(addr: Address): string {
    let ll = '';
    if (addr && addr.geo && addr.geo.coordinates) {
      ll = `${addr.geo.coordinates[1]},${addr.geo.coordinates[0]}`;
    }
    return ll;
  }

  openRespondDialog(content: TemplateRef<any>) {
		this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.modalRef.result.then(
			(result: any) => {
				// this.closeResult = `Closed with: ${result}`;
			},
			(reason: any) => {
				// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  onFileSelected(event: any): void {
    this.fileData = event.target.files[0];
    // this.uploadFile(fileToUpload.items(0));
  }

  async uploadFile() {
    function _stripFilename(fn: string) {
      return fn.substring(fn.lastIndexOf("\\") + 1, fn.length); 
    }

    await this.Jobs.uploadFile(this.id, _stripFilename(this.fileForm.value.selectedFile), this.fileForm.value.fileDescription, this.fileData);
    this.form.patchValue({ selectedFile: '' });
    this.files$ = this.Jobs.getFiles(this.id);
  }

  async downloadFile(fn: string) {
    const url: {url: string} = await firstValueFrom(this.Shared.getPreSignedURLForFile(fn, 'GET'));
    window.open(url.url);
  }

  getOfferClass = this.Jobs.getOfferClass;
}
