<div class="client-contact-details">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Contact Details</h4>
		<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
	</div>
	<div class="modal-body">
		<form [formGroup]="form">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">  
        <li [ngbNavItem]="1">
          <button ngbNavLink>Details</button>
          <ng-template ngbNavContent>
            <div formGroupName="details">
              <div class="mb-3">
                <label for="title" class="form-label">Title</label>
                <select class="form-select" formControlName="title" id="title">
                  <option *ngFor="let s of titles$ | async" value="{{ s.value }}">{{ s.key }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="firstName" class="form-label">First Name</label>
                <input type="text" class="form-control" id="firstName" placeholder="First Name" formControlName="firstName">
              </div>

              <div class="mb-3">
                <label for="lastName" class="form-label">Last Name</label>
                <input type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName">
              </div>

              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email">
              </div>

              <div class="mb-3">
                <label for="telephone" class="form-label">Telephone</label>
                <input type="tel" class="form-control" id="telephone" placeholder="Telephone" formControlName="telephone">
              </div>

              <div class="mb-3">
                <label for="mobile" class="form-label">Mobile</label>
                <input type="tel" class="form-control" id="mobile" placeholder="Mobile" formControlName="mobile">
              </div>

              <hr>

              <div class="mb-3">
                <label for="notes" class="form-label">Notes</label>
                <textarea id="notes" class="form-control" formControlName="notes"></textarea>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <button ngbNavLink>Address</button>
          <ng-template ngbNavContent>
            <div formGroupName="address">
              <div class="mb-3">
                <label for="line1" class="form-label">Line 1</label>
                <input type="text" class="form-control" id="line1" placeholder="Line 1" formControlName="line1">
              </div>

              <div class="mb-3">
                <label for="line2" class="form-label">Line 2</label>
                <input type="text" class="form-control" id="line2" placeholder="Line 2" formControlName="line2">
              </div>

              <div class="mb-3">
                <label for="city" class="form-label">City</label>
                <input type="text" class="form-control" id="city" placeholder="City" formControlName="city">
              </div>
              
              <div class="mb-3">
                <label for="county" class="form-label">County</label>
                <input type="text" class="form-control" id="county" placeholder="County" formControlName="county">
              </div>

              <div class="mb-3">
                <label for="postcode" class="form-label">Post Code</label>
                <input type="text" class="form-control" id="postcode" placeholder="Post Code" formControlName="postcode">
              </div>

              <div class="mb-3">
                <label for="country" class="form-label">Country</label>
                <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country">
              </div>
            </div>
          </ng-template>
        </li>

        <!-- <li [ngbNavItem]="3">
          <button ngbNavLink>Billing</button>
          <ng-template ngbNavContent>
            <div formGroupName="billing">
              <div class="mb-3">
                <label for="invoiceReference" class="form-label">Invoice Reference</label>
                <input type="text" class="form-control" id="invoiceReference" placeholder="Invoice Reference" formControlName="invoiceReference">
              </div>

              <div class="mb-3">
                <label for="hourlyRate" class="form-label">Hourly Rate</label>
                <input type="number" class="form-control" id="hourlyRate" placeholder="Hourly Rate" formControlName="hourlyRate">
              </div>

              <div class="mb-3">
                <label for="translationRate" class="form-label">Translation Rate</label>
                <input type="number" class="form-control" id="translationRate" placeholder="Translation Rate" formControlName="translationRate">
              </div>

              <div class="mb-3">
                <label for="mileageRate" class="form-label">Mileage Rate</label>
                <input type="number" class="form-control" id="mileageRate" placeholder="Mileage Rate" formControlName="mileageRate">
              </div>

              <div class="mb-3">
                <label for="travelRate" class="form-label">Travel Rate</label>
                <input type="number" class="form-control" id="travelRate" placeholder="Travel Rate" formControlName="travelRate">
              </div>
            </div>

          </ng-template>
        </li> -->
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </form>
	</div>
	<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
      <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
      <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
      Save
    </button>
	</div>
</div>