import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength, Min, MinLength, ValidateNested } from "class-validator";
import { JangoMongooseBase } from "../shared/jango-mongoose-base";
import { ClientSpecialism } from "./client-specialism.enum";
import { ClientStatus } from "./client-status.enum";
import { ClientContact } from './client-contact.class';

export class ClientRate {
  @IsString()
  @MinLength(1)
  service: string = '';

  @IsNumber()
  @Min(0)
  firstHour: number = 0;

  @IsNumber()
  @Min(0)
  subsequentHour: number = 0;

  @IsNumber()
  @Min(0)
  travelHour: number = 0;

  @IsNumber()
  @Min(0)
  mileage: number = 0;
}

export class Client extends JangoMongooseBase {

  @IsString()
  @IsNotEmpty()
  name: string = '';

  @IsString()
  @IsNotEmpty()
  code: string = '';

  @IsString()
  @IsNotEmpty()
  type: string = '';

  // @IsEnum(ClientSpecialism)
  // @IsNotEmpty()
  // specialism: ClientSpecialism = ClientSpecialism.Other;

  @IsEnum(ClientStatus)
  @IsNotEmpty()
  status: ClientStatus = ClientStatus.Active;

  @IsOptional()
  @MaxLength(1000)
  notes: string = '';

  @IsString()
  invoicingMode: string = '';

  @ValidateNested({ each: true })
  rates: ClientRate[] = [];

  contacts: ClientContact[] = [];
}