<div>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Rate Card</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let l of rateCards$ | async">
        <td scope="row">
          <a href="/rate-cards/{{l._id}}">
            {{ l.name }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <a href="rate-cards/create" class="btn btn-primary">
    <fa-icon [icon]="Icons.newRecord"></fa-icon>
    Add
  </a>
</div>