import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor() {}

  error(title: string, msg: string): void {
    Swal.fire({
      title: title || 'Error',
      text: msg || 'Unknown Error',
      icon: 'error',
      confirmButtonText: 'Close',
    });
  }

  alert(title: string, msg: string, btn: string): void {
    Swal.fire({
      title: title || 'Error',
      text: msg || 'Unknown Error',
      icon: 'info',
      confirmButtonText: 'OK',
    });
  }

  confirm(title: string, msg: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        text: msg,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
}
