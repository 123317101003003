import { IsOptional, IsString } from 'class-validator';
import { JangoMongooseBase } from '../shared/jango-mongoose-base';

export class Service extends JangoMongooseBase {
  @IsString()
  name: string = '';

  @IsString()
  @IsOptional()
  pricingModel: string = '';

  @IsString()
  @IsOptional()
  unit: string = '';
}
