import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  private pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

	fromModel(value: string | null): NgbTimeStruct | null {
		if (!value) {
			return null;
		}
		const split = value.split(':');
		return {
			hour: parseInt(split[0], 10),
			minute: parseInt(split[1], 10),
			second: 0,
		};
	}

	toModel(time: NgbTimeStruct | null): string | null {
		return time != null ? `${this.pad(time.hour)}:${this.pad(time.minute)}:0}` : null;
	}
}
