import { Component } from '@angular/core';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, BehaviorSubject, switchMap, of, debounceTime, distinctUntilChanged } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { JangoPagedQueryResponse } from '../../shared/jango-paged-query-response.class';
import { JangoPagedQuery } from '../../shared/jango-paged-query.class';
import { Interpreter } from '../interpreters.class';
import { InterpretersService } from '../interpreters.service';
import { IGenericLookup } from '../../shared/generic-lookup.interface';
import { EnumToArray } from '../../shared/enum-helper';
import { Languages } from '../../shared/languages';
import { Skill } from '../../skills/skill.class';
import { SkillsService } from '../../skills/skills.service';

@Component({
  selector: 'app-interpreters-list',
  templateUrl: './interpreters-list.component.html',
  styleUrls: ['./interpreters-list.component.scss']
})
export class InterpretersListComponent extends JangoComponentBase {
  public data$: Observable<JangoPagedQueryResponse<Interpreter>>;
  public pageNo$ = new BehaviorSubject<number>(1);
  public query$ = new BehaviorSubject<JangoPagedQuery>({
    query: {},
    pageNo: 1,
    pageSize: 10,
    sort: 'name',
  });
  private query: JangoPagedQuery = {
    query: {},
    pageNo: 1,
    pageSize: 10,
    sort: ''
  };
  public pageNo = 1;
  public pageSize = 10;
  public isLoading: boolean = false;
  public form: FormGroup;
  // public languages = Languages;
  public languages$: Observable<Skill[]>;
  public memberships$: Observable<IGenericLookup[]>;
  public subj$ = new BehaviorSubject<string>('');

  constructor(private Auth: AuthService, private Interpreters: InterpretersService, private Skills: SkillsService, private fb: FormBuilder) {
    super(Auth);

    this.memberships$ = this.Interpreters.getMemberships();
    this.languages$ = Skills.findAll();

    this.form = this.fb.group({
      lastName: [''],
      firstName: [''],
      linguistNumber: [''],
      language: [''],
      membership: [''],
    });

    this.data$ = this.query$.pipe(
      switchMap((query) => this.getPage(query)),
    );

    this.subscribers.push(this.subj$.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        // this.consoleMessages.push(value);
        this.search();
      }));
    // this.data$ = this.getPage();
  }

  private getPage(query: JangoPagedQuery) {
    return this.Interpreters.find(query);
  }

  public pageChanged(pageNo: number) {
    this.query.pageNo = pageNo;
    this.query$.next(this.query);
    // this.data$ = this.getPage();
  }

  public search() {
    this.query = {
      query: this.form.value,
      pageNo: 1,
      pageSize: this.pageSize,
      sort: 'lastName firstName'
    };
    this.query$.next(this.query);
  }
}
