import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { EnumToArray } from "./enum-helper";
import { Title } from "./title.enum";
import { IGenericLookup } from "./generic-lookup.interface";
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private API: ApiService) {}

  titles$: Observable<IGenericLookup[]> = of(EnumToArray(Title));

  getCurrencies() {
    return this.API.get<IGenericLookup[]>('lookups/currencies');
  }

  getPreSignedURLForFile(fn: string, method: 'GET' | 'PUT'): Observable<{ url: string }> {
    return this.API.get<{ url: string }>(`shared/signed-url?fn=${fn}&method=${method}`);
  }
}