<div class="clients">
  <form [formGroup]="form" class="mb-3">
    <div class="row">
      <div class="col-lg mb-3">
        <label for="code" class="form-label">Code</label>
        <input type="text" class="form-control form-control-sm" id="code" placeholder="Code" formControlName="code" (ngModelChange)="this.subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="name" class="form-label">Company Name</label>
        <input type="text" class="form-control form-control-sm" id="name" placeholder="Name" formControlName="name" (ngModelChange)="this.subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="contact" class="form-label">Contact</label>
        <input type="text" class="form-control form-control-sm" id="contact" placeholder="Contact" formControlName="contact" (ngModelChange)="this.subj$.next($event)">
      </div>
    </div>
  </form>
  <div *ngIf="data$ | async as data">
    <table class="table table-hover table-striped">
      <thead>
        <th class="d-none d-md-table-cell">Code</th>
        <th>Name</th>
      </thead>
      <tbody>
        <tr *ngFor="let client of data.data">
          <td class="d-none d-md-table-cell" scope="row">
            <a routerLink="/clients/{{client._id}}">
              {{ client.code }}
            </a>
          </td>
          <td>
            <a routerLink="/clients/{{client._id}}">
              {{ client.name }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col">
        <a class="btn btn btn-primary" routerLink="create">
          <fa-icon [icon]="Icons.newRecord"></fa-icon>
          Add
        </a>
      </div>
      <div class="col">
        <ngb-pagination
          class="float-end"
          [page]="data.pageNo"
          [pageSize]="data.pageSize"
          (pageChange)="pageChanged($event)"
          [collectionSize]="data.totalCount"></ngb-pagination>
      </div>
    </div>
  </div>

</div>