<div class="login">
  <div class="row justify-content-center">
    <div class="col-xl-6 align-self-center">
      <div class="alert alert-danger" role="alert" *ngIf="loginFailed" i18n="@@loginFailedMsg">
        Unable to login. Bad email or password.
      </div>
    
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Login</h5>

          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-floating">
              <input id="email" type="text" formControlName="username" class="form-control" placeholder="Email Address">
              <label for="email" class="form-label">Email Address: </label>
            </div>
        
            <div class="form-floating">
              <input id="password" type="password" formControlName="password" class="form-control" placeholder="Password">
              <label for="password" class="form-label">Password: </label>
            </div>
        
            <hr>
            <button class="btn btn-primary" style="width: 100%;" type="btn" [disabled]="!loginForm.valid">Login</button>
          </form>
        </div>
      </div>
      <p style="text-align: center; margin-top: 10px;">
        <a [routerLink]="'/forgotten-password'" i18n="@@forgottenPassLink">Forgotten password?</a>
      </p>
    </div>
  </div>
</div>
