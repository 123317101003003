import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericModalComponent } from 'src/app/shared/modal/generic-modal/generic-modal.component';
import { PasswordValidator } from 'src/app/shared/validators/password.validator';
import { JangoComponentBase} from '../../shared/jango-base.class';
import { UsersService } from '../users.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})

export class VerifyUserComponent extends JangoComponentBase implements OnInit {
  resetPasswordForm = this.fb.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
  }, {
    validator: PasswordValidator('password', 'confirmPassword')
  })
  token: string = '';

  constructor(protected override authService: AuthService, private fb: FormBuilder, private ngbModal: NgbModal, private userService: UsersService, private router: Router, private route: ActivatedRoute) {
    super(authService);
    // this.token = this.route.snapshot.params['id'];
    this.token = this.route.snapshot.queryParamMap.get('t') as string;
  }

  ngOnInit(): void {

  }

  async resetPassword() {
    if (this.authService.passwordsComplex(this.resetPasswordForm.value.password, this.resetPasswordForm.value.confirmPassword)) {
      await this.userService.resetPassword(this.resetPasswordForm.value, this.token).toPromise();
      const modalRef = this.ngbModal.open(GenericModalComponent);
      modalRef.componentInstance.config = {
        title: 'Password Set.',
        body: 'You can now log in.',
        closeText: 'OK'
      };
      this.router.navigateByUrl('/login');
    } else {
      const modalRef = this.ngbModal.open(GenericModalComponent);
      modalRef.componentInstance.config = {
        title: 'Password not Complex enough.',
        type: 'danger',
        body: 'Passwords must be greater than 8 characters in length which must contain upper/lower case, at least 1 number.',
        closeText: 'OK'
      };
    }
  }
}
