import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { Skill } from '../skill.class';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SkillsService } from '../skills.service';
import { InterpretersService } from '../../interpreters/interpreters.service';
import { Interpreter } from '../../interpreters/interpreters.class';
import { JangoPagedQueryResponse } from '../../shared/jango-paged-query-response.class';

@Component({
  selector: 'app-skills-details',
  templateUrl: './skills-details.component.html',
  styleUrl: './skills-details.component.scss'
})
export class SkillsDetailsComponent extends JangoComponentBase implements OnInit {
  @Input() data: Skill = {
    name: '',
    preferredInterpreterId: '',
    preferredInterpreterName: '',
    _createdBy: '',
    _createdOn: null,
    _updatedBy: '',
    _updatedOn: null
  }
  form: FormGroup;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // preferredInterpreter?: Interpreter;
  // interpreters$: Observable<Interpreter[]>;

  constructor(private Auth: AuthService, private Skills: SkillsService, private Interpreters: InterpretersService, private fb: ClassValidatorFormBuilderService, private modalRef: NgbActiveModal) {
    super(Auth);

    this.form = fb.group(Skill, {
      _id: [''],
      name: [''],
      preferredInterpreter: [''],
      preferredInterpreterId: [''],
      preferredInterpreterName: ['']
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.form.patchValue( { ...this.data, preferredInterpreter: { _id: this.data.preferredInterpreterId, name: this.data.preferredInterpreterName } });
    }
  }

  async save() {
    const formData = this.form.value;
    delete formData.preferredInterpreter;
    this.saving$.next(true);
    await firstValueFrom(this.Skills.save(formData));
    this.modalRef.close();
  }

  cancel() {
    this.modalRef.dismiss('');
  }

  search = (text$: Observable<string>): Observable<Interpreter[]> => {
    return text$.pipe(      
        debounceTime(200), 
        distinctUntilChanged(),
        switchMap( (searchText) =>  this.Interpreters.findAll(`?name=${searchText}`)),
    );                 
  }

  resultFormatter(result: Interpreter) {
    if (result.name) {
      return result.lastName;
    } else {
      return `${result.lastName}, ${result.firstName}`;
    }
  }

  selectItem($event: any) {
    if ($event && $event.item) {
      this.form.patchValue({ preferredInterpreterName: `${$event.item.lastName}, ${$event.item.firstName}`, preferredInterpreterId: $event.item._id });
      // this.form.updateValueAndValidity();
      console.log(this.form.value.preferredInterpreterName);
    }
  }

  inputFormatter(interpreter: Interpreter) {
    // return this.data?.preferredInterpreterName || '';
    console.log('inputFormatter');
    if (interpreter) {
      if (interpreter.name && interpreter.name !== "") {
        return interpreter.name;
      }

      if (interpreter.lastName) {
        return `${interpreter.lastName}, ${interpreter.firstName}`;
      }
      // return interpreter.name || `${interpreter.lastName}, ${interpreter.firstName}`;
    }
    return '';
  }
}
