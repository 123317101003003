import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../shared/api/api.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;

  constructor(public authService: AuthService, public router: Router, private api: ApiService) {}

  isTokenExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  injectToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    });
    return request;
  }

  requestRefreshedAccessToken(token: string) {
    return this.api.post(`auth/refresh`, { refreshToken: token })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (request.url)
    // if (['localhost', 'neonmountain'].indexOf(request)
    if (request.url.indexOf('localhost') === -1 && request.url.indexOf('neonmountain') === -1) {
      return next.handle(request);
    }

    if (this.authService.getToken() && this.authService.getRefreshToken()) {
      const accessExpired = this.isTokenExpired(this.authService.getToken());
      const refreshExpired = this.isTokenExpired(this.authService.getRefreshToken());

      // Has our existing token expired?
      if (accessExpired && refreshExpired) {
        this.authService.logout();
        this.router.navigateByUrl('/welcome');
      }

      if (accessExpired && !refreshExpired) {
        // If so, issue request to get new token

        if (!this.refreshTokenInProgress) {
          this.refreshTokenInProgress = true;
          return this.requestRefreshedAccessToken(this.authService.getRefreshToken()).pipe(
            switchMap((authResponse: any) => {
              this.authService.setAccessToken(authResponse.accessToken);
              this.authService.setRefreshToken(authResponse.refreshToken);
              this.refreshTokenInProgress = false;
              return next.handle(this.injectToken(request, this.authService.getToken()));
            }),
          );
        } else {
          return next.handle(request);
        }
      }
    }
    return next.handle(this.injectToken(request, this.authService.getToken()));
  }
}
