<div class="container">
  <h1 i18n="@@user-details">User Maintenance</h1>

    <div class="row">
      <div class="col-3">
        <div
        class="btn-group mx-2"
        role="group"
        aria-label="Stock View Selection"
      >
        <input
          type="radio"
          class="btn-check"
          name="instock"
          id="instock"
          autocomplete="off"
          value="emailAddress"
          [(ngModel)]="searchChoice"
        />
        <label class="btn btn-primary" for="instock">Search by Email</label>

        <input
          type="radio"
          class="btn-check"
          name="available"
          id="available"
          autocomplete="off"
          value="fullName"
          [(ngModel)]="searchChoice"
        />
        <label class="btn btn-primary" for="available">Search by Name</label>
      </div>
      </div>
      <div class="col-4">
        <input type="text" class="form-control" id="searchText" [(ngModel)]="searchText" name="searchText" placeholder="Search Text">
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-primary"
          [disabled]="!searchText || !searchChoice || loading" (click)="getPagedUsers(1, pageSize)">Search</button>
        <!-- &nbsp;
        <button type="button" class="btn btn-primary" style="margin-top: 10px;" [disabled]="loading"
          (click)="resetFilter()">Reset</button>
      </div> -->
    </div>
  </div>

  <div class="row">
        <table class="table table-responsive-sm table-striped table-hover cl-user-table" style="margin-bottom: 0px;">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let u of users | paginate: { id: 'adminEntries', itemsPerPage: pageSize, currentPage: page, totalItems: totalEntries }"
              [class.strike]="u.disabled">
              <td>{{u.firstName}}</td>
              <td>{{u.lastName}}</td>
              <td>{{u.email}}</td>
              <td>
                <button type="button" class="btn btn-primary float-end" (click)="editUser(u)">Edit</button>
              </td>
            <tr *ngIf="users && users.length === 0">
              <td colspan="8">Sorry, your search returned 0 results.</td>
            </tr>
          </tbody>
        </table>
  </div>
  <hr>
  <div class="row">
    <button class="btn btn-primary" style="margin-top: 0px; width: 100%;" type="button"
      *ngIf="this.hasPermission(['user-create'])" (click)="addNewUser()">
      Add new User
    </button>

    <div *ngIf="totalEntries > 0" class="cl-pagination-wrapper">
      <pagination-controls class="cl-pagination" (pageChange)="getPage($event)" id="adminEntries"
        previousLabel="Prev" nextLabel="Next"></pagination-controls>
      <div class="entryCount">{{totalEntries}} items</div>
    </div>
  </div>
</div>
