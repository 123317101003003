<div class="forgottenPassword">

  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Forgotten Password</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">Enter your email to recover your account.</h6>
          <!-- <div class="alert alert-info" *ngIf="validationfailed">Please check your email address</div> -->
            <form [formGroup]="newPasswordForm" (ngSubmit)="requestNewPassword()" *ngIf="!emailSent">
              <div class="form-floating">
                <input type="text" formControlName="email" class="form-control" name="email" placeholder="Email address" />
                <label for="email" class="form-label">Email Address: </label>
              </div>
              <hr>
              <button type="btn" class="btn btn-primary" style="width: 100%;" [disabled]="!newPasswordForm.valid"
                i18n="@@resetPassBtn">Reset Password</button>
            </form>
            <div *ngIf="emailSent" class="alert alert-info">You will receive a reset link to your email shortly.</div>
        </div>
      </div>
    </div>
  </div>
</div>
