import { IsEmail, IsEnum, IsNumber, IsOptional, IsString, Min, MinLength, ValidateNested } from "class-validator";
import { Address } from "../shared/address.class";
import { JangoMongooseBase } from "../shared/jango-mongoose-base";
import { Title } from "../shared/title.enum";

export class ClientDetails {
  @IsString()
  clientId: string = '';

  @IsEnum(Title)
  title: Title = Title.Mr;

  @IsString()
  @MinLength(1)
  firstName: string = '';

  @IsString()
  @MinLength(1)
  lastName: string = '';

  @IsEmail()
  @IsOptional()
  email: string = '';

  @IsString()
  telephone: string = '';

  @IsString()
  mobile: string = '';
}

export class ClientBilling {
  @IsNumber()
  @Min(1)
  hourlyRate: number = 0;

  @IsNumber()
  @Min(1)
  translationRate: number = 0;

  @IsNumber()
  @Min(1)
  mileageRate: number = 0;

  @IsNumber()
  @Min(1)
  travelRate: number = 0;

  @IsString()
  @IsOptional()
  invoiceReference: string = '';
}

export class ClientContact extends JangoMongooseBase {
  @IsString()
  clientId: string = '';

  @IsEnum(Title)
  title: Title = Title.Mr;

  @IsString()
  firstName: string = '';

  @IsString()
  lastName: string = '';

  @IsString()
  @IsOptional()
  invoiceReference: string = '';

  @IsEmail()
  @IsOptional()
  email: string = '';

  @IsString()
  @IsOptional()
  telephone: string = '';

  @IsString()
  @IsOptional()
  mobile: string = '';

  @ValidateNested()
  address: Address = {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
  };

  @IsNumber()
  @Min(1)
  hourlyRate: number = 0;

  @IsNumber()
  @Min(1)
  translationRate: number = 0;

  @IsNumber()
  @Min(1)
  mileageRate: number = 0;

  @IsNumber()
  @Min(1)
  travelRate: number = 0;

  @IsString()
  @IsOptional()
  notes: string = '';
}