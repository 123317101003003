import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api/api.service';
import { JangoBaseService } from '../shared/jango-base.service';
import { Skill } from './skill.class';

@Injectable({
  providedIn: 'root'
})
export class SkillsService extends JangoBaseService<Skill> {
  override endpoint = 'skills';

  constructor(override API: ApiService) {
    super(API);
  }
}