
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Error</h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger">
      <p>{{message}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Ok</button>
  </div>
