import { Component } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, switchMap, tap } from 'rxjs';
import { Client } from '../client.class';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { ClientsService } from '../clients.service';
import { AuthService } from '../../auth/auth.service';
import { JangoPagedQueryResponse } from '../../shared/jango-paged-query-response.class';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JangoPagedQuery } from '../../shared/jango-paged-query.class';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent extends JangoComponentBase {
  public data$: Observable<JangoPagedQueryResponse<Client>>;
  public pageNo$ = new BehaviorSubject<number>(1);
  public query$ = new BehaviorSubject<JangoPagedQuery>({
    query: {},
    pageNo: 1,
    pageSize: 10,
    sort: 'name',
  });
  private query: JangoPagedQuery = {
    query: {},
    pageNo: 1,
    pageSize: 10,
    sort: ''
  };
  public pageNo = 1;
  public pageSize = 10;
  public isLoading: boolean = false;
  public form: FormGroup;
  public subj$ = new BehaviorSubject<string>('');

  constructor(private Auth: AuthService, private Clients: ClientsService, private fb: FormBuilder) {
    super(Auth);

    this.form = this.fb.group({
      code: [''],
      name: [''],
      contact: [''],
    });

    this.data$ = this.query$.pipe(
      switchMap((query) => this.getPage(query)),
    );

    this.subscribers.push(this.subj$.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        // this.consoleMessages.push(value);
        this.search();
      }));

    // this.data$ = this.getPage();
  }

  private getPage(query: JangoPagedQuery) {
    return this.Clients.find(query);
  }

  public pageChanged(pageNo: number) {
    this.query.pageNo = pageNo;
    this.query$.next(this.query);
    // this.data$ = this.getPage();
  }

  public search() {
    this.query = {
      query: this.form.value,
      pageNo: 1,
      pageSize: this.pageSize,
      sort: 'name'
    };
    this.query$.next(this.query);
  }
}
