import { Component } from '@angular/core';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { RateCard } from '../rate-card.class';
import { RateCardsService } from '../rate-cards.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Skill } from '../../skills/skill.class';

@Component({
  selector: 'app-rate-cards-list',
  templateUrl: './rate-cards-list.component.html',
  styleUrl: './rate-cards-list.component.scss'
})
export class RateCardsListComponent extends JangoComponentBase {
  rateCards$: Observable<RateCard[]>;
  constructor(private Auth: AuthService, private RateCards: RateCardsService) {
    super(Auth);
    this.rateCards$ = RateCards.findAll();
  }
}
