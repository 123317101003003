import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, BehaviorSubject, switchMap, debounceTime, distinctUntilChanged } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Client } from '../../clients/client.class';
import { ClientsService } from '../../clients/clients.service';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { JangoPagedQueryResponse } from '../../shared/jango-paged-query-response.class';
import { JangoPagedQuery } from '../../shared/jango-paged-query.class';
import { Job } from '../job.class';
import { JobsService } from '../jobs.service';
import * as moment from 'moment';
import { NgbTimeAdapter, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/ngdate-adapter.class';
import { NgbTimeStringAdapter } from '../../shared/ngtime-adapter.class';
import { InterpretersService } from '../../interpreters/interpreters.service';
import { Interpreter } from '../../interpreters/interpreters.class';
import { IGenericLookup } from '../../shared/generic-lookup.interface';
import { SharedService } from '../../shared/shared.service';
import { Service } from '../../services/service.class';
import { ServicesService } from '../../services/services.service';
import { SkillsService } from '../../skills/skills.service';
import { Skill } from '../../skills/skill.class';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrl: './jobs-list.component.scss',
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class JobsListComponent extends JangoComponentBase {
  public data$: Observable<JangoPagedQueryResponse<Job>>;
  public clients$: Observable<Client[]>;
  public interpreters$: Observable<Interpreter[]>;
  languages$: Observable<Skill[]>;
  services$: Observable<Service[]>;
  statuses$: Observable<IGenericLookup[]>;
  public pageNo$ = new BehaviorSubject<number>(1);
  public query$ = new BehaviorSubject<JangoPagedQuery>({
    query: {},
    pageNo: 1,
    pageSize: 10,
    sort: 'name',
  });
  private query: JangoPagedQuery = {
    query: {},
    pageNo: 1,
    pageSize: 10,
    sort: ''
  };
  public pageNo = 1;
  public pageSize = 10;
  public isLoading: boolean = false;
  public form: FormGroup;
  public subj$ = new BehaviorSubject<string>('');

  constructor(private Auth: AuthService, private Jobs: JobsService, private Clients: ClientsService, private Skills: SkillsService, private Services: ServicesService, private Interpreters: InterpretersService, private Shared: SharedService, private fb: FormBuilder) {
    super(Auth);

    this.clients$ = this.Clients.findAll();
    this.interpreters$ = this.Interpreters.findAll();
    this.services$ = this.Services.findAll();
    this.languages$ = Skills.findAll();
    this.statuses$ = Jobs.getStatuses();

    this.form = this.fb.group({
      jobNo: [''],
      clientId: [''],
      endClient: [''],
      dateFrom: [''],
      dateTo: [''],
      interpreterId: [''],
      service: [''],
      language: [''],
      status: [''],
    });

    this.data$ = this.query$.pipe(
      switchMap((query) => this.getPage(query)),
    );

    this.subscribers.push(this.subj$.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        // this.consoleMessages.push(value);
        this.search();
      }));
    // this.data$ = this.getPage();
  }

  private getPage(query: JangoPagedQuery) {
    return this.Jobs.find(query);
  }

  public pageChanged(pageNo: number) {
    this.query.pageNo = pageNo;
    this.query$.next(this.query);
    // this.data$ = this.getPage();
  }

  public search() {
    const q = { ... this.form.value };

    this.query = {
      query: q,
      pageNo: 1,
      pageSize: this.pageSize,
      sort: 'name'
    };
    this.query$.next(this.query);
  }

  public getStatusClass = this.Jobs.getStatusClass;
}
