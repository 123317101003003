import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiURL: string = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  get<T>(path:string):Observable<T> {
    return this.http.get<T>(this.apiURL + path);
  }

  post<T>(path: string, data: any): Observable<T> {
    return this.http.post<T>(this.apiURL + path, data);
  }

  put<T>(path: string, data: any): Observable<T> {
    return this.http.put<T>(this.apiURL + path, data);
  }

  patch<T>(path: string, data: any): Observable<T> {
    return this.http.patch<T>(this.apiURL + path, data);
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(this.apiURL + path);
  }
}
