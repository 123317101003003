import { IsOptional, IsString, MinLength } from "class-validator";

export class Address {
  @IsString()
  @MinLength(1)
  line1: string = '';
  
  @IsString()
  @IsOptional()
  line2: string = '';

  @IsString()
  city: string = '';

  @IsString()
  @IsOptional()
  county: string = '';

  @IsString()
  @MinLength(1)
  postcode: string = '';

  @IsString()
  @IsOptional()
  country: string = '';

  geo?: {
    coordinates: number[];
  } = { coordinates: [] };
}