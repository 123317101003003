<div class="job-offer" *ngIf="job$ | async as data" [formGroup]="form">
  <h3>Job {{data.job.jobNo}}</h3>

  <div>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">  
      <li [ngbNavItem]="'details'">
        <button ngbNavLink>Details</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <table class="table">
                <tbody>
                  <tr>
                    <td colspan="2"><h6>Job Details</h6></td>
                  </tr>
                  <tr>
                    <th>Language</th>
                    <td>{{data.job.language}}</td>
                  </tr>
                  <tr>
                    <th>Date/Time</th>
                    <td>{{data.job.date | date: 'dd/MM/yyyy'}} {{data.job.startTime}}</td>
                  </tr>
                  <tr>
                    <th>Duration</th>
                    <td>{{data.job.durationBooked}}</td>
                  </tr>
                  <tr>
                    <th>Non-English Speaker</th>
                    <td>{{data.job.endClient}}</td>
                  </tr>
                  <tr *ngIf="!data.willQuote">
                    <th>Fee</th>
                    <td>&pound;{{data.fee | number: '1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><h6><br>Client Details</h6></td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{data.client.name}}</b>
                    </td>
                    <td>
                      <a href="maps://maps.google.com/?q={{getLatLong(data.job.address)}}" target="_blank">
                        <div>{{ data.job.address.line1 }}</div>
                        <div>{{ data.job.address.line2 }}</div>
                        <div>{{ data.job.address.city }}</div>
                        <div>{{ data.job.address.county }}</div>
                        <div>{{ data.job.address.postcode }}</div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="'notes'">
        <button ngbNavLink>Notes</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6" style="min-height: 400px;">
              {{data.job.notes}}
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="'docs'">
        <button ngbNavLink>Documents</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6" [formGroup]="fileForm">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th colspan="2">Filename</th>
                    <!-- <th>&nbsp;</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let l of files$ | async; index as i">
                    <td colspan="2">
                      <a href="javascript: void(0)" (click)="downloadFile(l.filename)">
                        {{ l.description }}
                      </a>
                    </td>
                    <!-- <td>
                      <fa-icon class="float-end pointer" [icon]="Icons.delete" (click)="deleteFile(l._id!)"></fa-icon>
                    </td> -->
                  </tr>
                </tbody>
                <tfoot *ngIf="data.status === 'available'">
                  <tr>
                    <td style="padding-left: 0;">
                      <input type="text" class="form-control" formControlName="fileDescription" placeholder="Description">
                    </td>
                    <td style="padding-right: 0">
                      <div class="input-group">
                        <input type="file" class="form-control" formControlName="selectedFile" (change)="onFileSelected($event)">
                        <button class="btn btn-primary" [disabled]="!fileForm.valid" (click)="uploadFile()">
                          <fa-icon [icon]="Icons.newRecord"></fa-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>

    <div class="row">
      <div class="col-lg-6 d-grid">
        <button class="btn btn-primary" *ngIf="!isRespondedTo(data.status)" (click)="openRespondDialog(responder)">Respond</button>
        <button class="btn btn-primary" *ngIf="isRespondedTo(data.status)" (click)="openRespondDialog(completer)">Complete</button>
      </div>
    </div>

  </div>

  <ng-template #completer let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Complete Job</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <label class="form-label">Duration Spent</label>
          <input type="number" class="form-control" formControlName="durationCompleted">
        </div>
        <div class="mb-3">
          <label class="form-label">Mileage</label>
          <input type="number" class="form-control" formControlName="mileage">
        </div>
        <div class="mb-3">
          <label class="form-label">Expenses</label>
          <input type="number" class="form-control" formControlName="expenses">
        </div>
        <div class="mb-3">
          By clicking the 'Complete Job' button you are confirming that you have completed the work as expected and uploaded your proof of work and any relevent expenses.
        </div>
      </form>
    </div>
    <div class="modal-footer px-0" style="justify-content: center; display: block;">
      <div class="row">
        <div class="col d-grid">
          <button class="btn btn-primary" (click)="completeJob(data.job._id)">Complete Job</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #responder let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Respond to Offer</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
          <div class="mb-3" *ngIf="data.willQuote">
            <label class="form-label">Your fee</label>
            <input type="number" class="form-control" formControlName="fee">
          </div>
          <div class="mb-3">
            <label class="form-label">Response Notes (optional)</label>
            <!-- <input type="text" class="form-control" formControlName="notes"> -->
             <textarea class="form-control" rows="6" formControlName="notes"></textarea>
          </div>
      </form>
    </div>
    <div class="modal-footer px-0" style="justify-content: center; display: block;">
      <div class="row">
        <div class="col-4 d-grid">
          <button class="btn btn-danger" [disabled]="!form.valid" (click)="declineOffer(id)">
            <fa-icon [icon]="Icons.declined"></fa-icon>
            <span class="d-none d-lg-inline">
              Unavailable
            </span>
          </button>
        </div>
        <div class="col-4 d-grid">
          <button class="btn btn-warning" [disabled]="!form.valid" (click)="tentativeOffer(id)">
            <fa-icon [icon]="Icons.tentative"></fa-icon>
            <span class="d-none d-lg-inline">
              Tentative
            </span>
          </button>
        </div>
        <div class="col-4 d-grid">
          <button class="btn btn-success" [disabled]="!form.valid" (click)="acceptOffer(id)">
            <fa-icon [icon]="Icons.accepted"></fa-icon>
            <span class="d-none d-lg-inline">
              Available
            </span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <!--
  <div *ngIf="!isRespondedTo(data.status)">
    <div class="row">
      <div class="col-lg-4 d-flex align-items-stretch mb-3">
          <div class="card w-100 mh-100">
            <div class="card-body d-flex flex-column">
              <h6 class="card-subtitle mb-2 text-body-secondary">Job Details</h6>
              <table class="table">
                <tbody>
                  <tr>
                    <th>Language</th>
                    <td>{{data.job.language}}</td>
                  </tr>
                  <tr>
                    <th>Date/Time</th>
                    <td>{{data.job.date | date: 'dd/MM/yyyy'}} {{data.job.startTime}}</td>
                  </tr>
                  <tr>
                    <th>Duration</th>
                    <td>{{data.job.durationBooked}}</td>
                  </tr>
                  <tr>
                    <th>Non-English Speaker</th>
                    <td>{{data.job.endClient}}</td>
                  </tr>
                  <tr *ngIf="!data.willQuote">
                    <th>Fee</th>
                    <td>&pound;{{data.fee | number: '1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch mb-3">
        <div class="card w-100 mh-100">
          <div class="card-body d-flex flex-column">
            <h6 class="card-subtitle mb-2 text-body-secondary">Client Details</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch mb-3">
        <div class="card w-100 mh-100">
          <div class="card-body d-flex flex-column">
            <h6 class="card-subtitle mb-2 text-body-secondary">Job Notes</h6>
            {{data.job.notes}}
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <h6>Your Response</h6>
      <div class="col-xl-4">
        <div class="mb-3" *ngIf="data.willQuote">
          <label class="form-label">Your fee</label>
          <input type="number" class="form-control" formControlName="fee">
        </div>
        <div class="mb-3">
          <label class="form-label">Response Notes (optional)</label>
          <input type="text" class="form-control" formControlName="notes">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 d-grid">
        <button class="btn btn-lg btn-success" [disabled]="!form.valid" (click)="acceptOffer(id)">
          <fa-icon [icon]="Icons.accepted"></fa-icon>
          Available
        </button>
      </div>
      <div class="col-md-4 d-grid">
        <button class="btn btn-lg btn-warning" [disabled]="!form.valid" (click)="tentativeOffer(id)">
          <fa-icon [icon]="Icons.tentative"></fa-icon>
          Tentative
        </button>
      </div>
      <div class="col-md-4 d-grid">
        <button class="btn btn-lg btn-danger" [disabled]="!form.valid" (click)="declineOffer(id)">
          <fa-icon [icon]="Icons.declined"></fa-icon>
          Unavailable
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isRespondedTo(data.status)">
    You have already responded to this offer.
    <br>
    <button class="btn btn-sm" [ngClass]="getOfferClass(data.status)" style="width: 10em">
      {{ data.status }}&nbsp;
    </button>
  </div>
  -->
</div>