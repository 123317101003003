import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMaintenanceDetailComponent } from './user-maintenance-detail/user-maintenance-detail.component';
import { UserMaintenanceListComponent } from './user-maintenance-list/user-maintenance-list.component';
import { UsersRoutingModule } from './user-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';

@NgModule({
  // providers: [UsersService],
  declarations: [
    UserMaintenanceListComponent,
    UserMaintenanceDetailComponent,
    VerifyUserComponent,
    ForgottenPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    UsersRoutingModule
  ],
})
export class UsersModule { }
