import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RateCardsListComponent } from './rate-cards-list/rate-cards-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { RateCardsRoutingModule } from './rate-cards-routing.module';
import { RateCardDetailsComponent } from './rate-card-details/rate-card-details.component';



@NgModule({
  declarations: [RateCardsListComponent, RateCardDetailsComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RateCardsRoutingModule,
  ]
})
export class RateCardsModule { }
