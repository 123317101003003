<div>
  <div class="modal-header">
    <h5 id="modal-basic-title">User Details</h5>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <form [formGroup]="userMainForm">
        <div class="form-floating">
          <input id="email" type="text" formControlName="email" class="form-control" placeholder="Email Address">
          <label for="email" class="form-label">Email Address: </label>
        </div>

        <div class="form-floating">
          <input id="firstName" type="text" formControlName="firstName" class="form-control" placeholder="First Name">
          <label for="firstName" class="form-label">First Name: </label>
        </div>

        <div class="form-floating">
          <input id="lastName" type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
          <label for="lastName" class="form-label">Last Name: </label>
        </div>
        <hr>

        <div *ngIf="this.hasPermission(['user-change-permissions'])">
          <label for="permissions" class="form-label">Permissions: </label>
          <div class="row">
            <div class="col-6">
              <small>Availible Permissions</small>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center"
                  *ngFor="let a of filteredPermissions let ind=index">{{a.value}} <button class="btn btn-success"
                    (click)="addPermission(a)"
                    *ngIf="this.hasPermission(['user-change-permissions'])">Add</button></li>
              </ul>
            </div>
            <div class="col-6">
              <small>Current Permissions</small>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center"
                  *ngFor="let u of user.permissions let ind=index">{{u}} <button class="btn btn-danger"
                    (click)="removePermission(ind)"
                    *ngIf="this.hasPermission(['user-change-permissions'])">Remove</button></li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" type="btn" (click)="cancel()">Cancel</button>

    <button *ngIf="userId && this.hasPermission(['user-reset-password'])" class="btn btn-primary" type="btn" (click)="resetPassword(user.email)">Reset Password</button>
    <button *ngIf="userId && !user.disabled && this.hasPermission(['user-delete'])" class="btn btn-danger" type="btn" (click)="disableEnableUser(true)">Disable</button>
    <button *ngIf="userId && user.disabled && this.hasPermission(['user-delete'])" class="btn btn-danger" type="btn" (click)="disableEnableUser(false)">Reinstate</button>

    <button class="btn btn-success" type="btn" [disabled]="!userMainForm.valid" *ngIf="checkPermission()" (click)="createUpdate()">{{getButtonText()}}</button>
  </div>
</div>