import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { ClientBilling, ClientContact, ClientDetails } from '../client-contact.class';
import { AuthService } from '../../auth/auth.service';
import { ClassValidatorFormBuilderService } from 'ngx-reactive-form-class-validator';
import { Address } from '../../shared/address.class';
import { ActivatedRoute } from '@angular/router';
import { Title } from '../../shared/title.enum';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { IGenericLookup } from '../../shared/generic-lookup.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../clients.service';

@Component({
  selector: 'app-client-contact-details',
  templateUrl: './client-contact-details.component.html',
  styleUrls: ['./client-contact-details.component.scss']
})
export class ClientContactDetailsComponent extends JangoComponentBase implements OnInit {
  @Input() clientId: string = '';
  @Input() data: ClientContact = {
    clientId: '',
    title: Title.Mr,
    firstName: '',
    lastName: '',
    invoiceReference: '',
    email: '',
    telephone: '',
    mobile: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      postcode: '',
      county: '',
      country: ''
    },
    hourlyRate: 0,
    translationRate: 0,
    mileageRate: 0,
    travelRate: 0,
    notes: '',
    _createdBy: '',
    _createdOn: null,
    _updatedBy: '',
    _updatedOn: null
  };
  titles$: Observable<IGenericLookup[]>;
  saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  form: FormGroup;
  // detailsForm: FormGroup;
  // addressForm: FormGroup;
  // billingForm: FormGroup;

  constructor(private  Auth: AuthService, private fb: ClassValidatorFormBuilderService, private Clients: ClientsService, private Shared: SharedService, public modalRef: NgbActiveModal) {
    super(Auth);

    this.titles$ = this.Shared.titles$;

    /*
    this.detailsForm = this.fb.group(ClientDetails, {
      title: [Title.Mr],
      firstName: [''],
      lastName: [''],
      email: [''],
      telephone: [''],
      mobile: [''],
      notes: [''],
    });

    this.addressForm = this.fb.group(Address, {
      line1: [''],
      line2: [''],
      city: [''],
      county: [''],
      postcode: [''],
      county: [''],
    });

    this.billingForm = this.fb.group(ClientBilling, {
      hourlyRate: [0],
      translationRate: [0],
      mileageRate: [0],
      travelRate: [0],
      invoiceReference: [''],
    });
    */

    this.form = this.fb.group(ClientContact, {
      details: this.fb.group(ClientDetails, {
        title: [Title.Mr],
        firstName: [''],
        lastName: [''],
        email: [''],
        telephone: [''],
        mobile: [''],
        notes: [''],
      }),
      address:  this.fb.group(Address, {
        line1: [''],
        line2: [''],
        city: [''],
        county: [''],
        postcode: [''],
        country: [''],
      }),
      // billing: this.fb.group(ClientBilling, {
      //   hourlyRate: [0],
      //   translationRate: [0],
      //   mileageRate: [0],
      //   travelRate: [0],
      //   invoiceReference: [''],
      // }),
    });

    // this.form.patchValue(this.data);
  }

  ngOnInit(): void {
    if (this.data) {
      console.log(`Patching ${JSON.stringify(this.data)}`)
      // this.form.patchValue({});
      this.form.patchValue({
        details: this.data,
        address: this.data.address,
        billing: this.data,
      })
      // this.detailsForm.patchValue(this.data);
      // this.addressForm.patchValue(this.data);
      // this.billingForm.patchValue(this.data);
    }
  }

  async save() {
    const formData = this.form.value;
    // {
    //   details : this.detailsForm.value,
    //   address: this.addressForm.value,
    //   billing: this.billingForm.value,
    // }
    this.saving$.next(true);
    const payload = {
      clientId: this.clientId,
      ...formData.details,
      address: {...formData.address },
      ...formData.billing,
    }
    console.log(JSON.stringify(payload));
    if (!!this.data._id) payload._id = this.data._id;
    await firstValueFrom(this.Clients.saveContact(this.clientId, payload));
    this.modalRef.close();
  }

  cancel() {
    this.modalRef.dismiss();
  }
}
