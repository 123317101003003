import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { UsersModule } from './users.module';
import { ApiService } from '../shared/api/api.service';
import { environment } from '../../environments/environment';

let _user: any = null;

@Injectable({
  providedIn: UsersModule
})
export class UsersService {
  constructor(private api: ApiService, private router: Router) {}

  findUserById(userId: string) {
    return this.api.get(`users/${userId}`);
  }

  register(regData: object | null | undefined) {
    return this.api.post(`users/register`, regData);
  }

  getPagedUsers = (pageNumber: number, pageSize: number, searchText: string | null | undefined, searchChoice: string | null | undefined) => {
    let uri = `users?pageNum=${pageNumber}&pageSize=${pageSize}`;
    let queryParams = [];
    if (searchText) {
      queryParams.push(`searchText=${searchText}`);
    }
    if (searchChoice) {
      queryParams.push(`searchChoice=${searchChoice}`);
    }
    if (queryParams.length) {
      uri += '&' + queryParams.join('&');
    }
    return this.api.get(uri);
  }

  createUser(email: string | null | undefined, firstName: string | null | undefined, lastName: string | null | undefined, permissions: never[] | null | undefined) {
    return this.api.post(`users`, { email: email, firstName: firstName, lastName: lastName, permissions: permissions });
  }

  updateUser(id: string, email: string | null | undefined, firstName: string | null | undefined, lastName: string | null | undefined, permissions: never[] | null | undefined) {
    return this.api.put(`users/${id}`, { email: email, firstName: firstName, lastName: lastName, permissions: permissions });
  }

  disableEnableUser(userId: string, disable: boolean) {
    const mode = disable ? 'disable' : 'enable';
    return this.api.put(`users/${userId}/${mode}`, {});
  }

  profile() {
    return this.api.get(`auth/profile`);
  }

  requestNewPassword(email: string | null | undefined) {
    return this.api.post(`users/requestpwd`, { email: email });
  }

  resetPassword(data: object, token: string) {
    data = { ...data, token };
    return this.api.post(`users/resetpwd?t=${token}`, data);
  }
}
