import { Component, EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, Subscriber, Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { IUser } from "../auth/user.interface";
import { Icons } from "./icons.class";

@Component({
  selector: 'app-nav',
  // templateUrl: './nav.component.html',
  template: '',
  styleUrls: []
})
export abstract class JangoComponentBase implements OnDestroy {
  currentUser$: Observable<IUser | null>;
  subscribers: Subscription[] = [];
  Icons = Icons;

  constructor(protected authService: AuthService) {
    this.currentUser$ = authService.currentUser$;
  }

  ngOnDestroy(): void {
    this.subscribers.forEach( s => s.unsubscribe() );
  }

  hasPermission(permissions: string[]): boolean {
    return this.authService.hasPermission(permissions);
  }
}