import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { GenericModalComponent } from 'src/app/shared/modal/generic-modal/generic-modal.component';
import { AuthService } from '../../auth/auth.service';
import { JangoComponentBase} from '../../shared/jango-base.class';
import { UserMaintenanceDetailComponent } from '../user-maintenance-detail/user-maintenance-detail.component';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-maintenance-list',
  templateUrl: './user-maintenance-list.component.html',
  styleUrls: ['./user-maintenance-list.component.scss']
})
export class UserMaintenanceListComponent extends JangoComponentBase implements OnInit {
  currentUser: any = [];
  users: any = [];
  deleting = false;
  searchChoice = 'emailAddress';
  searchText = '';
  loading = false;
  pageSize = 10;
  page = 1;
  totalEntries = 0;
  selectedUser: any = null;
  userMainForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    permissions: new FormControl([])
  });
  apiPermissions: any = {};
  filteredPermissions: any;

  constructor(protected override authService: AuthService, private usersService: UsersService, private ngbModal: NgbModal) {
    super(authService);
  }

  async ngOnInit() {
    this.getPagedUsers(this.page, this.pageSize);
  }

  async getPagedUsers(pageNumber: number, pageSize: number) {
    this.loading = true;
    const res: any = await this.usersService.getPagedUsers(pageNumber, pageSize, this.searchText, this.searchChoice).toPromise();
    this.users = null;
    this.users = res.items;
    this.totalEntries = res.totalItems;
    this.page = pageNumber;
    this.pageSize = pageSize;
    this.loading = false;
  }

  getPage(page: any) {
    this.getPagedUsers(page, this.pageSize);
    this.page = page;
  }

  async resetFilter() {
    this.loading = true;
    this.searchText = '';
    this.searchChoice = '';
    this.page = 1;
    const res: any = await this.usersService.getPagedUsers(this.page, this.pageSize, this.searchText, this.searchChoice).toPromise();
    this.users = null;
    this.users = res.items;
    this.totalEntries = res.totalItems;
    this.loading = false;
  }

  editUser(user: any) {
    this.openUploadDialog(user._id);
  }

  openUploadDialog(userId: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true
    };
    const modalInstance = this.ngbModal.open(UserMaintenanceDetailComponent, ngbModalOptions);
    modalInstance.componentInstance.userId = userId;
    modalInstance.result.then((doc) => {
      this.getPagedUsers(this.page, this.pageSize);
    }, (reason) => {
    });
  }

  addNewUser() {
    this.openUploadDialog('');
  }
}
