import { Injectable } from '@angular/core';
import { JangoBaseService } from '../shared/jango-base.service';
import { Interpreter, InterpreterFile } from './interpreters.class';
import { ApiService } from '../shared/api/api.service';
import { Observable, firstValueFrom } from 'rxjs';
import { IGenericLookup } from '../shared/generic-lookup.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InterpretersService extends JangoBaseService<Interpreter> {
  override endpoint: string = 'interpreters';

  constructor(override API: ApiService, private HTTP: HttpClient) {
    super(API);
  }

  getQualifications(): Observable<IGenericLookup[]> {
    return this.API.get('interpreters/qualifications');
  }

  getMemberships(): Observable<IGenericLookup[]> {
    return this.API.get('interpreters/memberships');
  }

  getFilesForInterpreter(id: string): Observable<InterpreterFile[]> {
    return this.API.get<InterpreterFile[]>(`interpreters/${id}/files`);
  }

  addFileForInterpreter(id: string, filename: string, description: string): Observable<InterpreterFile> {
    return this.API.post<InterpreterFile>(`interpreters/${id}/files`, {
      interpreterId: id,
      filename: String,
      descrpition: String,
    });
  }

  async uploadFileForInterpreter(id: string, filename: string, description: string, fileData: any) {
    // Generate new filename
    const ext = filename.split('.').reverse()[0];
    const newFilename = (new Date()).valueOf() + '.' + ext;

    // Get the pre-signed URL
    const url = await firstValueFrom(this.API.get<{ url: string }>(`shared/signed-url?fn=${newFilename}&method=PUT`));
    
    // Now post the file to the endpoint.
    await firstValueFrom(this.HTTP.put(url.url, fileData));

    // And now add the InterpreterFile record
    return firstValueFrom(this.API.post(`interpreters/${id}/files`, { interpreterId: id, filename: newFilename, description }));
  }

  deleteFileForInterpreter(interpreterId: string, fileId: string) {
    return this.API.delete(`interpreters/${interpreterId}/files/${fileId}`);
  }
}
