import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../guards/auth-guard.service';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { UserMaintenanceListComponent } from './user-maintenance-list/user-maintenance-list.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';

const routes: Routes = [
  {
    path: 'users/verify',
    component: VerifyUserComponent
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
  },
  {
    path: 'users',
    canActivate: [AuthGuardService],
    data: { permission: ['user'] },
    component: UserMaintenanceListComponent
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
