import { IsBoolean, IsEmail, IsEnum, IsNumber, IsOptional, IsPhoneNumber, IsString, Max, Min, MinLength, ValidateNested } from "class-validator";
import { JangoMongooseBase } from "../shared/jango-mongoose-base";
import { Title } from "../shared/title.enum";
import { Address } from "../shared/address.class";

export class InterpreterFile {
  _id?: string = '';
  interpreterId: string = '';
  filename: string = '';
  description: string = '';
}

export class InterpreterRates {
  @ValidateNested()
  legal: InterpreterRate = new InterpreterRate;

  @ValidateNested()
  nhs: InterpreterRate = new InterpreterRate;

  @ValidateNested()
  other: InterpreterRate = new InterpreterRate;
}

export class InterpreterQualification {
  @IsString()
  type: string = '';

  @IsString()
  description: string = '';
}

export class InterpreterMembership {
  @IsString()
  type: string = '';

  @IsString()
  description: string = '';
}

export class InterpreterRate {
  @IsString()
  @MinLength(1)
  serviceId: string = '';

  @IsString()
  @MinLength(1)
  service: string = '';

  @IsString()
  @MinLength(1)
  pricingModel: string = '';

  @IsString()
  unit: string = '';

  @IsNumber()
  @Min(0)
  oneHourRate: number = 0;

  @IsNumber()
  @Min(0)
  standardHourRate: number = 0;

  @IsNumber()
  @Min(0)
  travelHourRate: number = 0;

  @IsNumber()
  @Min(0)
  mileageRate: number = 0;

  @IsNumber()
  @Min(0)
  unitRate: number = 0;
}

export class BankDetails {
  @IsString()
  name: string = '';

  @IsString()
  accNo: string = '';

  @IsString()
  sort: string = '';
}

export class Interpreter extends JangoMongooseBase {
  @IsEnum(Title)
  title: Title = Title.Mr;

  @IsString()
  firstName: string = '';

  @IsString()
  lastName: string = '';

  name?: string = '';

  @IsString()
  linguistNumber: string = '';

  @IsEmail()
  @IsOptional()
  email: string = '';

  @IsString()
  @IsOptional()
  telephone: string = '';

  @IsString()
  @IsOptional()
  mobile: string = '';

  @ValidateNested()
  address: Address = {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
    country: ""
  };

  @IsBoolean()
  willQuote: boolean = false;

  @IsString({ each: true })
  languages: string[] = [];

  @IsString({ each: true })
  memberships: string[] = [];

  // @IsString({ each: true })
  qualifications: InterpreterQualification[] = [];

  @IsString()
  @IsOptional()
  utr: string = '';

  @IsString()
  currency: string = 'GBP';

  @IsString()
  @IsOptional()
  notes: string = '';

  @ValidateNested({ each: true })
  rates!: InterpreterRate[];

  @ValidateNested()
  bankDetails!: BankDetails;

  @IsNumber()
  @Min(1)
  @Max(5)
  rating: number = 1;
}
