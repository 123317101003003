<div class="clients">
  <form [formGroup]="form" class="mb-3">
    <div class="row">
      <div class="col-lg mb-3">
        <label for="lastName" class="form-label">Last Name</label>
        <input type="text" class="form-control form-control-sm" id="lastName" placeholder="Last Name" formControlName="lastName" (ngModelChange)="subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="firstName" class="form-label">First Name</label>
        <input type="text" class="form-control form-control-sm" id="firstName" placeholder="First Name" formControlName="firstName" (ngModelChange)="subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="linguistNumber" class="form-label">Linguist Number</label>
        <input type="text" class="form-control form-control-sm" id="linguistNumber" placeholder="Linguist Number" formControlName="linguistNumber" (ngModelChange)="subj$.next($event)">
      </div>
      <div class="col-lg mb-3">
        <label for="language" class="form-label">Langauge</label>
        <select class="form-select form-select-sm" id="language" formControlName="language" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let l of languages$ | async" value="{{l.name}}">{{l.name}}</option>
        </select>
      </div>
      <div class="col-lg mb-3">
        <label for="membership" class="form-label">Membership</label>
        <select class="form-select form-select-sm" id="membership" formControlName="membership" (change)="search()">
          <option value="">(All)</option>
          <option *ngFor="let l of memberships$ | async" value="{{l.value}}">{{l.value}}</option>
        </select>
      </div>
    </div>
  </form>
  <div *ngIf="data$ | async as data">
    <table class="table table-hover table-striped">
      <thead>
        <th>Last Name</th>
        <th>First Name</th>
        <th class="d-none d-md-table-cell">Rating</th>
      </thead>
      <tbody>
        <tr *ngFor="let interpreter of data.data">
          <td scope="row">
            <a routerLink="/interpreters/{{interpreter._id}}">
              {{ interpreter.lastName }}
            </a>
          </td>
          <td>{{ interpreter.firstName }}</td>
          <td class="d-none d-md-table-cell">
            <star-rating-control [rating]="interpreter.rating" [disabled]="true" size="medium"></star-rating-control>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col">
        <a class="btn btn btn-primary" routerLink="create">
          <fa-icon [icon]="Icons.newRecord"></fa-icon>
          Add
        </a>
      </div>
      <div class="col">
        <ngb-pagination
          class="float-end"
          [page]="data.pageNo"
          [pageSize]="data.pageSize"
          (pageChange)="pageChanged($event)"
          [collectionSize]="data.totalCount"></ngb-pagination>
      </div>
    </div>
  </div>

</div>