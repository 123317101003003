import { Component } from '@angular/core';
import { JangoComponentBase } from '../../shared/jango-base.class';
import { AuthService } from '../../auth/auth.service';
import { SkillsService } from '../skills.service';
import { Observable } from 'rxjs';
import { Skill } from '../skill.class';
import { SkillsDetailsComponent } from '../skills-details/skills-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-skills-list',
  templateUrl: './skills-list.component.html',
  styleUrl: './skills-list.component.scss'
})
export class SkillsListComponent extends JangoComponentBase {
  skills$: Observable<Skill[]>;
  constructor(private Auth: AuthService, private Skills: SkillsService, private NgbModal: NgbModal) {
    super(Auth);
    this.skills$ = Skills.findAll();
  }

  addSkill() {
    const modalRef = this.NgbModal.open(
      SkillsDetailsComponent,
      { size: 'lg', centered: true }
    );
    
    modalRef.componentInstance.data = { name: '' };

    modalRef.result
      .then(() => {
        this.skills$ = this.Skills.findAll();
      })
      .catch(() => {

      })
  }

  editSkill(data: Skill) {
    const modalRef = this.NgbModal.open(
      SkillsDetailsComponent,
      { size: 'lg', centered: true }
    );
    
    modalRef.componentInstance.data = data;
  
    modalRef.result
      .then(() => {
        this.skills$ = this.Skills.findAll();
      })
      .catch(() => {
  
      })
  }
}