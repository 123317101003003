<div class="row ">
  <div class="mb-3 col-xl-4 col-lg-6" *ngFor="let o of offers$ | async">
    <div class="card">
      <div class="card-header">
        <h6 class="mb-0">
          {{ o.jobNo }}
          <button class="float-end btn btn-sm" [ngClass]="getOfferClass(o.status)" style="width: 10em" *ngIf="mode === 'offers'">
            {{ o.status }}&nbsp;
          </button>
        </h6>
      </div>
      <table class="table mb-0">
        <thead>
        </thead>
        <tbody>
          <tr>
            <th>
              Service
            </th>
            <td>{{ o.service }}</td>
          </tr>
          <tr>
            <th>
              Language
            </th>
            <td>{{ o.language }}</td>
          </tr>
          <tr>
            <th>
              Date
            </th>
            <td>{{ o.date | date: 'dd/MM/YYYY' }} {{ o.startTime }} </td>
          </tr>
          <tr *ngIf="o.pricingModel === 'full'">
            <th>
              Duration
            </th>
            <td>{{ o.duration }}</td>
          </tr>
          <tr *ngIf="o.pricingModel !== 'full'">
            <th>
              {{ o.unit }}
            </th>
            <td>{{ o.units }}</td>
          </tr>
          <tr>
            <th>
              Fee
            </th>
            <td>&pound;{{ o.fee | number:'1.2-2' }}</td>
          </tr>
          <tr>
            <th>
              Address
            </th>
            <td>
              <a href="maps://maps.google.com/?q={getLatLong({{o.address)}" target="_blank">
                <div>{{ o.address.line1 }}</div>
                <div>{{ o.address.line2 }}</div>
                <div>{{ o.address.city }}</div>
                <div>{{ o.address.county }}</div>
                <div>{{ o.address.postcode }}</div>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">
              <div class="d-grid gap-2">
                <a [routerLink]="['/job-offers', o.offerId]" class="btn btn-primary">More Info...</a>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>