<nav class="navbar navbar-light bg-light navbar-expand-lg sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/nm-logo.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top">
      Aladna
    </a>
    <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>

    <!-- Step 2: Add the ngbCollapse directive to the element below. -->
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav" *ngIf="currentUser$ | async as currentUser">
        <!--<li class="nav-item active">
          <a class="nav-link" [routerLink]="'.'" (click)="isMenuCollapsed = true">Features</a>
        </li>-->
        <li class="nav-item" *ngIf="currentUser.email">
          <a class="nav-link" routerLink="/dashboard" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Dashboard</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['clients'])">
          <a class="nav-link" routerLink="/clients" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Clients</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['interpreters'])">
          <a class="nav-link" routerLink="/interpreters" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Interpreters</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['jobs'])">
          <a class="nav-link" routerLink="/jobs" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Jobs</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['invoices'])">
          <a class="nav-link" routerLink="/invoices" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Invoices</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['user'])">
          <a class="nav-link" routerLink="/users" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Users</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['skills'])">
          <a class="nav-link" routerLink="/skills" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Languages</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['rate-cards'])">
          <a class="nav-link" routerLink="/rate-cards" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Rate Cards</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email && this.hasPermission(['services'])">
          <a class="nav-link" routerLink="/services" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Services</a>
        </li>
        <li class="nav-item" *ngIf="currentUser.email">
          <a class="nav-link" routerLink="#" (click)="logout()">Log Out</a>
        </li>
        <li class="nav-item" *ngIf="!currentUser.email">
          <a class="nav-link" routerLink="/login" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Login</a>
        </li>
        <li class="nav-item" *ngIf="!currentUser.email">
          <a class="nav-link" routerLink="/register" [routerLinkActive]="['active']" (click)="isMenuCollapsed = true">Register</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<nav class="navbar navbar-footer fixed-bottom bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" href="https://www.neonmountain.co.uk" target="_blank">
      <img src="assets/nm-logo.png" alt="Logo" width="18" height="14" class="d-inline-block align-text-top">
      &copy;2024 Neon Mountain
    </a>
    <!-- <a href="https://www.neonmountain.co.uk">Made by Neon Mountain</a> -->
  </div>
</nav>