<div class="container-xl" *ngIf="data$ | async as data">
  <div class="row">
    <div class="col">
      <h3>
        Job {{data.jobNo}}
        <button class="btn btn-secondary" *ngIf="!isNew" (click)="pdf()">
          <fa-icon [icon]="Icons.print"></fa-icon>
        </button>
      </h3>
    </div>
  </div>

  <form [formGroup]="form">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">  
      <li [ngbNavItem]="'details'">
        <button ngbNavLink>Details</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6 order-lg-first order-md-last order-sm-last">
              <!-- <div class="mb-3" *ngIf="offers$ | async">
                <label for="title" class="form-label">Assigned Interpreter</label>
                <select class="form-select" formControlName="clientId" id="clientId" (change)="getContacts()">
                  <option value="">(Unassigned)</option>
                  <option *ngFor="let o of offers$ | async" value="{{ o.interpreterId }}">{{ o.lastName + ', ' + o.firstName }}</option>
                </select>
              </div>

              <hr> -->

              <div class="mb-3">
                <label for="title" class="form-label">Client</label>
                <select class="form-select" formControlName="clientId" id="clientId" (change)="getContacts()">
                  <option *ngFor="let c of clients$ | async" value="{{ c._id }}">{{ c.name }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Contact</label>
                <select class="form-select" formControlName="clientContactId" id="clientContactId">
                  <option *ngFor="let s of contacts$ | async" value="{{ s._id }}">{{ s.lastName }}, {{ s.firstName }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Service</label>
                <!-- <select class="form-select" formControlName="service" id="service">
                  <option *ngFor="let s of services$ | async" value="{{ s.name }}">{{ s.name }}</option>
                </select> -->

                <select class="form-select" formControlName="serviceId" (change)="serviceSelected($event)">
                  <option value=""></option>
                  <option *ngFor="let s of services$ | async" value="{{s._id}}" [attr.pricingModel]="s.pricingModel" [attr.unit]="s.unit">{{s.name}}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Rate Card</label>
                <select class="form-select" formControlName="rateCardId" id="rateCardId">
                  <option *ngFor="let s of rateCards$ | async" value="{{ s._id }}">{{ s.name }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Language</label>
                <select class="form-control" formControlName="language">
                  <option *ngFor="let l of languages$ | async" value="{{ l.name }}">{{ l.name }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Non-English Speaker</label>
                <input type="text" class="form-control" formControlName="endClient">
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Attending Professional</label>
                <input type="text" class="form-control" formControlName="attendingProfessional">
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Client Reference / Case No</label>
                <input type="text" class="form-control" formControlName="customerReference">
              </div>

              <div class="mb-3">
                <label for="contact" class="form-label">Job Group Reference</label>
                <input type="text" class="form-control" formControlName="parentReference">
              </div>

              <div class="mb-3">
                <label for="date" class="form-label">Date</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    name="date"
                    formControlName="date"
                    ngbDatepicker
                    #d="ngbDatepicker"
                  />
                  <button class="btn btn-primary" (click)="d.toggle()" type="button">
                    <fa-icon [icon]="Icons.calendar"></fa-icon>
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="form.value.pricingModel === 'full'">
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="startTime" class="form-label">Start Time</label>
                    <ngb-timepicker formControlName="startTime" [spinners]="false" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="durationRequested" class="form-label">Booked Dur.</label>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control" formControlName="durationRequested">
                      <span class="input-group-text">Hrs</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="durationBooked" class="form-label">Offered Dur.</label>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control" formControlName="durationBooked">
                      <span class="input-group-text">Hrs</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4">
                  <div class="mb-3">
                    <label for="endTime" class="form-label">End Time</label>
                    <ngb-timepicker formControlName="endTime" [spinners]="false" [readonlyInputs]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="endTime" class="form-label">End Time</label>
                    <ngb-timepicker formControlName="clientEndTime" [spinners]="false" [readonlyInputs]="true" />
                  </div>
                </div> -->
              </div>


              <div class="row" *ngIf="form.value.pricingModel === 'unit'">
                <div class="col">
                  <label for="units" class="form-label">Quantity</label>
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" name="units" formControlName="units">
                    <span class="input-group-text">{{ form.value.unit }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="price" class="form-label">Price</label>
                    <div class="input-group">
                      <span class="input-group-text">&pound;</span>
                      <input type="number" class="form-control" formControlName="price">
                      <button class="btn btn-primary" type="button" [disabled]="isPriceDisabled()" (click)="getClientPrice()" title="Calculate the price from client rates">
                        <fa-icon [icon]="Icons.calculate"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 order-lg-last order-md-first order-sm-first interpreter-box" *ngIf="data.interpreterId">
              <div class="alert alert-warning">
                <div class="mb-3">
                  <label for="interpreterName" class="form-label">Assigned Interpreter</label>
                  <input type="text" class="form-control" name="interpreterName" id="interpreterName" readonly value="{{data.interpreterName}}">
                </div>
                <div class="mb-3">
                  <label for="fee" class="form-label">Agreed Fee</label>
                  <div class="input-group mb-3">
                    <span class="input-group-text">&pound;</span>
                    <input type="text" class="form-control" name="fee" id="fee" readonly value="{{data.fee}}">
                  </div>
                </div>
                <div class="mb-3">
                  <label for="adjustment" class="form-label">Adjustment +/-</label>
                  <div class="input-group mb-3">
                    <span class="input-group-text">&pound;</span>
                    <input type="number" class="form-control" name="adjustment" id="adjustment" formControlName="adjustment">
                  </div>
                </div>
                <hr>
                <div class="mb-3">
                  <label for="adjustment" class="form-label">Total</label>
                  <div class="input-group mb-3">
                    <span class="input-group-text">&pound;</span>
                    <input type="number" class="form-control" name="total" id="total" readonly value="{{ data.fee + form.value.adjustment }}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="'address'">
        <button ngbNavLink>Address</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6">
              <div formGroupName="address">
                <div class="mb-3">
                  <label class="form-label" >Line 1</label>
                  <input class="form-control" formControlName="line1" placeholder="Line 1">
                </div>
                
                <div class="mb-3">
                  <label class="form-label" >Line 2</label>
                  <input class="form-control" formControlName="line2" placeholder="Line 2">
                </div>
        
                <div class="mb-3">
                  <label class="form-label" >City</label>
                  <input class="form-control" formControlName="city" placeholder="City">
                </div>
        
                <div class="mb-3">
                  <label class="form-label" >County</label>
                  <input class="form-control" formControlName="county" placeholder="County">
                </div>
                
                <div class="mb-3">
                  <label class="form-label" >Postcode</label>
                  <input class="form-control" formControlName="postcode" placeholder="Postcode">
                </div>
                
                <div class="mb-3">
                  <label class="form-label" >Country</label>
                  <input class="form-control" formControlName="country" placeholder="Country">
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="'notes'">
        <button ngbNavLink>Notes</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <textarea class="form-control" style="height: 400px" formControlName="notes"></textarea>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="'docs'">
        <button ngbNavLink>Documents</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-6" [formGroup]="fileForm">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Filename</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let l of files$ | async; index as i">
                    <td>
                      <a href="javascript: void(0)" (click)="downloadFile(l.filename)">
                        {{ l.description }}
                      </a>
                    </td>
                    <td>
                      <fa-icon class="float-end pointer" [icon]="Icons.delete" (click)="deleteFile(l._id!)"></fa-icon>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td style="padding-left: 0;">
                      <input type="text" class="form-control" formControlName="fileDescription" placeholder="Description">
                    </td>
                    <td style="padding-right: 0">
                      <div class="input-group">
                        <input type="file" class="form-control" formControlName="selectedFile" (change)="onFileSelected($event)">
                        <button class="btn btn-primary" [disabled]="!fileForm.valid" (click)="uploadFile()">
                          <fa-icon [icon]="Icons.newRecord"></fa-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'offers'" *ngIf="!isNew">
        <button ngbNavLink>Offers</button>
        <ng-template ngbNavContent>
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Interpreter</th>
                <th>Rating</th>
                <th>Est. Distance</th>
                <th>Fee</th>
                <th>Status</th>
                <th>Notes</th>
                <th>Assigned</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let o of offers$ | async">
                <td>
                  <a href="/interpreters/{{o._id}}" target="_blank">
                    {{ o.lastName }}, {{ o.firstName }}
                  </a>
                  <fa-icon [icon]="Icons.star" style="color: gold" *ngIf="o.isPreferred" title="Preferred Interpreter"></fa-icon>
                </td>
                <td>
                  <star-rating-control [rating]="o.rating" [disabled]="true" size="medium"></star-rating-control>
                </td>
                <td>
                  {{ o.distance | number:'1.0-0' }} miles
                </td>
                <td>
                  <span *ngIf="(o.status != 'available' && o.status != 'tentative') && o.willQuote" class="badge text-bg-info">Quote</span>
                  <span *ngIf="!o.willQuote || (o.status == 'available' || o.status == 'tentative')">
                    &pound;{{ o.fee | number:'1.2-2'}}
                  </span>
                </td>
                <td>
                  <!-- {{ o.status }} -->
                  <button class="btn btn-sm btn-outline-primary" style="width: 10em" *ngIf="!data.interpreterId && !o.status" (click)="sendOffer(o._id, o.fee)">
                    <fa-icon [icon]="Icons.email" [fixedWidth]="true"></fa-icon>
                    Send Offer
                  </button>

                  <div ngbDropdown class="d-inline-block" *ngIf="o.status && !o.assigned">
                    <button type="button" class="btn btn-sm" style="width: 10em" [ngClass]="getOfferClass(o.status)" id="dropdownBasic1" ngbDropdownToggle>
                      <fa-icon [icon]="getOfferIcon(o.status)" [fixedWidth]="true"></fa-icon>
                      {{ getOfferText(o.status) }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button ngbDropdownItem (click)="acceptOffer(o)">
                        <fa-icon [icon]="Icons.accepted" [fixedWidth]="true"></fa-icon>
                        Available
                      </button>
                      <button ngbDropdownItem (click)="tentativeOffer(o)">
                        <fa-icon [icon]="Icons.tentative" [fixedWidth]="true"></fa-icon>
                        Tentative
                      </button>
                      <button ngbDropdownItem (click)="rejectOffer(o)">
                        <fa-icon [icon]="Icons.declined" [fixedWidth]="true"></fa-icon>
                        Unavailable
                      </button>
                      <hr>
                      <button ngbDropdownItem (click)="sendOffer(o._id)">
                        <fa-icon [icon]="Icons.email" [fixedWidth]="true"></fa-icon>
                        Re-send Offer
                      </button>
                    </div>
                  </div>

<!--                   
                  <button class="btn btn-sm btn-success" style="width: 10em" *ngIf="o.status == 'assigned'">
                    <fa-icon [icon]="Icons.accepted" [fixedWidth]="true"></fa-icon>
                    ASSIGNED
                  </button>

                  <button class="btn btn-sm btn-success" style="width: 10em" *ngIf="o.status == 'available'">
                    <fa-icon [icon]="Icons.accepted" [fixedWidth]="true"></fa-icon>
                    Available
                  </button>
                  <button class="btn btn-sm btn-danger" style="width: 10em" *ngIf="o.status == 'unavailable'">
                    <fa-icon [icon]="Icons.declined" [fixedWidth]="true"></fa-icon>
                    Unavailable
                  </button>
                  <button class="btn btn-sm btn-warning" style="width: 10em" *ngIf="o.status == 'tentative'">
                    <fa-icon [icon]="Icons.tentative" [fixedWidth]="true"></fa-icon>
                    Tentative
                  </button>
                  <button class="btn btn-sm btn-outline-warning" style="width: 10em" *ngIf="o.status == 'pending'">
                    <fa-icon [icon]="Icons.email" [fixedWidth]="true"></fa-icon>
                    Pending
                  </button> -->
                </td>
                <td>
                  <span title="{{o.offerNotes}}">{{ trimNotes(o.offerNotes) }}</span>
                </td>
                <td>
                  <button class="btn btn-sm btn-success" style="width: 10em" *ngIf="o.assigned" (click)="unassignInterpreter(o)">
                    <fa-icon [icon]="Icons.accepted" [fixedWidth]="true"></fa-icon>
                    ASSIGNED
                  </button>
                  <button class="btn btn-sm btn-outline-success" style="width: 10em" *ngIf="canAssignOffer(o)" (click)="assignInterpreter(o)">
                    <fa-icon [icon]="Icons.accepted" [fixedWidth]="true"></fa-icon>
                    Assign
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
    </ul>
  </form>

  <div [ngbNavOutlet]="nav"></div>

  <hr>
          
  <div class="mb-3 float-end">
    <a class="btn btn-outline-primary me-2" routerLink="/jobs">Cancel</a>
    <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
      <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
      <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
      Save
    </button>
  </div>
</div>