<div class="container-xl" *ngIf="data$ | async as data">
  <h3>Client Details</h3>

  <form [formGroup]="form">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">  
    <li [ngbNavItem]="1">
      <button ngbNavLink>Details</button>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-lg-6">
              <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" placeholder="Name" formControlName="name">
              </div>
          
              <div class="mb-3">
                <label for="code" class="form-label">Code</label>
                <input type="text" class="form-control" id="code" placeholder="Code" formControlName="code">
              </div>

              <div class="mb-3">
                <label for="type" class="form-label">Type</label>
                <select class="form-select" id="type" formControlName="type">
                  <option *ngFor="let s of types$ | async" value="{{ s.value }}">{{ s.key }}</option>
                </select>
              </div>
          
              <!-- <div class="mb-3">
                <label for="specialism" class="form-label">Specialism</label>
                <select class="form-select" formControlName="specialism" id="specialism">
                  <option *ngFor="let s of specialisms$ | async" value="{{ s.value }}">{{ s.key }}</option>
                </select>
              </div> -->
          
              <div class="mb-3">
                <label for="notes" class="form-label">Notes</label>
                <textarea class="form-control" id="notes" formControlName="notes"></textarea>
              </div>
          
              <div class="mb-3">
                <label for="status" class="form-label">Status</label>
                <select class="form-select" id="status" formControlName="status">
                  <option *ngFor="let s of statuses$ | async" value="{{ s.value }}">{{ s.key }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="status" class="form-label">Invoicing Mode</label>
                <select class="form-select" id="invoicingMode" formControlName="invoicingMode">
                  <option value="standard">Standard</option>
                  <option value="vat-only">VAT Only</option>
                </select>
              </div>
          </div>
        </div>
      </ng-template>
    </li>

    <!-- <li [ngbNavItem]="2">
      <button ngbNavLink>Rates</button>
      <ng-template ngbNavContent>
        <table class="table mb-5" formArrayName="rates">
          <thead>
            <tr>
              <th>Service</th>
              <th>1st Hour</th>
              <th>Sub. Hours</th>
              <th>Travel Hour</th>
              <th>Mileage</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rate of rates.controls; let i = index" [formGroupName]="i">
              <th class="align-middle">
                <select class="form-control" formControlName="service">
                  <option value=""></option>
                  <option *ngFor="let s of services$ | async" [value]="s.name">{{s.name}}</option>
                </select>
              </th>
              <td>
                <input type="number" class="form-control" formControlName="firstHour" type="number" placeholder="First Hour">
              </td>
              <td>
                <input type="number" class="form-control" formControlName="subsequentHour" type="number" placeholder="Subsequent Hour">
              </td>
              <td>
                <input type="number" class="form-control" formControlName="travelHour" type="number" placeholder="Travel Hour">
              </td>
              <td>
                <input type="number" class="form-control" formControlName="mileage" type="number" placeholder="Mileage">
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <button class="btn btn-primary" (click)="addRate()">
                  <fa-icon [icon]="Icons.newRecord"></fa-icon>
                  Add Rate
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </ng-template>
    </li> -->

    <li [ngbNavItem]="3" [disabled]="isNew">
      <button ngbNavLink>Contacts</button>
      <ng-template ngbNavContent>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="row">Name</th>
              <th class="d-none d-md-table-cell">Email</th>
              <th class="d-none d-md-table-cell">Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of contacts$ | async">
              <td scope="row">
                <a href="javascript: void(0)" (click)="editContact(c)">
                  {{c.lastName}}, {{c.firstName}}
                </a>
              </td>
              <td class="d-none d-md-table-cell">{{c.email}}</td>
              <td class="d-none d-md-table-cell">{{c.telephone}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col">
            <a class="btn btn-primary" (click)="addContact()">
              <fa-icon [icon]="Icons.newRecord"></fa-icon>
              Add
            </a>
          </div>
        </div>
      </ng-template>
    </li>

    </ul>
  </form>

  <div [ngbNavOutlet]="nav"></div>

  <hr>
          
  <div class="mb-3 float-end">
    <a class="btn btn-outline-primary me-2" routerLink="/clients">Cancel</a>
    <button class="btn btn-primary" (click)="save()" [disabled]="!form.valid">
      <fa-icon [icon]="Icons.loading" [spin]="true" *ngIf="saving$ | async"></fa-icon>
      <fa-icon [icon]="Icons.save" *ngIf="!(saving$ | async)"></fa-icon>
      Save
    </button>
  </div>

</div>