import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../guards/auth-guard.service';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobOfferDetailsComponent } from './job-offer-details/job-offer-details.component';

const routes: Routes = [
  {
    path: 'jobs',
    data: { permission: ['jobs'] },
    component: JobsListComponent
  },
  {
    path: 'jobs/create',
    data: { permission: ['jobs'] },
    component: JobDetailsComponent
  },
  {
    path: 'jobs/:id',
    data: { permission: ['jobs'] },
    component: JobDetailsComponent
  },
  {
    path: 'job-offers/:id',
    component: JobOfferDetailsComponent
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
