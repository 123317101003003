import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientsRoutingModule } from './clients-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ClientContactDetailsComponent } from './client-contact-details/client-contact-details.component';



@NgModule({
  declarations: [
    ClientsListComponent,
    ClientDetailsComponent,
    ClientContactDetailsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    ClientsRoutingModule,
    FontAwesomeModule,
  ]
})
export class ClientsModule { }
