import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { JangoPagedQuery } from './jango-paged-query.class';
import { JangoPagedQueryResponse } from './jango-paged-query-response.class';
import { JangoMongooseBase } from './jango-mongoose-base';

@Injectable({
  providedIn: 'root'
})
export class JangoBaseService<T extends JangoMongooseBase> {
  endpoint: string = '';

  constructor(
    public API: ApiService,
  ) { }

  findAll(query?: string) {
    let url = `${this.endpoint}`;
    if (query) url += query;
    return this.API.get<T[]>(url);
  }

  findById(id: string) {
    return this.API.get<T>(`${this.endpoint}/${id}`);
  }

  find(query: JangoPagedQuery) {
    return this.API.post<JangoPagedQueryResponse<T>>(`${this.endpoint}/query`, query);
  }

  create(payload: T) {
    return this.API.post<T>(`${this.endpoint}`, payload);
  }

  update(id: string, payload: T) {
    return this.API.patch<T>(`${this.endpoint}/${id}`, payload);
  }

  save(payload: T) {
    if (payload._id) {
      return this.update(payload._id, payload);
    } else {
      // Just to be sure
      delete payload._id;
      return this.create(payload);
    }
  }
}
